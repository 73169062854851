import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';

import { environment } from '../../../environments/environment';
import { State as PageState } from '../reducers/activate-user.reducer';
import { ActivateUser } from '../models/activate-user';

@Component({
  selector: 'dpc-activate-user-form',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="row justify-content-md-center">
      <div class="col-md-4">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">

          <div class="form-group">
            <div class="input-group">
              <div class="input-group-prepend"><span class="input-group-text daikin-lock-icon"></span></div>
              <input type="password"
                     class="form-control"
                     id="password"
                     placeholder="Password"
                     formControlName="password">
            </div>
            <ng-container *ngIf="form.controls['password'].dirty || form.controls['password'].touched">
              <div class="invalid-feedback"
                   *ngIf="form.controls['password'].errors?.required">
                Password is required
              </div>
              <div class="invalid-feedback"
                   *ngIf="form.controls['password'].errors?.pattern">
                The password length should be in 8 and 30 character.
                The password should contains minimum one lowercase, uppercase, numeric and special character: '_!?+@:;<>"
              </div>
            </ng-container>
          </div>

          <div class=" form-group">
            <div class="input-group">
              <div class="input-group-prepend"><span class="input-group-text daikin-lock-icon"></span></div>
              <input type="password"
                     class="form-control"
                     id="passwordConfirm"
                     placeholder="Password confirm"
                     formControlName="passwordConfirm">
            </div>
            <ng-container *ngIf="form.controls['passwordConfirm'].dirty || form.controls['passwordConfirm'].touched">
              <div class="invalid-feedback"
                   *ngIf="form.controls['passwordConfirm'].errors?.required">
                Password confirmation is required
              </div>

              <div class="invalid-feedback"
                   *ngIf="form.controls['passwordConfirm'].errors?.equalTo">
                Password not match with confirmation password
              </div>
            </ng-container>
          </div>

          <div class="alert alert-danger" role="alert" *ngIf="state.error">
            {{ state.error }}
          </div>

          <button type="submit" class="btn btn-primary half-fluid-button float-right"
                  [disabled]="!form.valid || state.pending">Submit
            <div class="animation-container" *ngIf="state.pending">
              <div class="dot"></div>
              <div class="dot"></div>
              <div class="dot"></div>
            </div>
          </button>
        </form>
      </div>
    </div>
  `
})
export class ActivateUseFormComponent {

  @Input() state: PageState;
  @Output() submitted = new EventEmitter<ActivateUser>();

  form: FormGroup;

  constructor() {
    const passwordControl = new FormControl('',
      [Validators.required, Validators.pattern(environment.passwordPattern)]);

    this.form = new FormGroup({
      password: passwordControl,
      passwordConfirm: new FormControl('', [Validators.required, CustomValidators.equalTo(passwordControl)])
    });

  }

  onSubmit(): void {
    if (this.form.valid) {
      const data: ActivateUser = {
        activationToken: this.state.token,
        password: this.form.value.password
      };
      this.submitted.emit(data);
    }
  }
}
