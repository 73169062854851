export const COLORS = [
  '#0099cc',
  '#782442',
  '#008634',
  '#a8252f',
  '#e46b08',
  '#68b022',
  '#fba800',
  '#512078',
  '#c8d200',
  '#8c1d76',
  '#5e2912',
  '#967c62'
];
