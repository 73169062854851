import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'dbp-send-activation-email',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <button type="button"
            class="btn btn-link btn-icon"
            title="Resending Activation email"
            [disabled]="sending"
            (click)="onSend()"
    >
      <span class="daikin-mail-icon" *ngIf="!sending"></span>
      <span class="fa fa-spin fa-spinner" *ngIf="sending"></span>
    </button>
  `,
  styles: [`
    .btn-icon {
      text-decoration: none;
    }
  `]
})
export class SendEmaiActivationComponent {
  @Input() sending: boolean;

  @Output() sendActivationEmail = new EventEmitter<void>();

  onSend(): void {
    if (this.sending)
      return;

    this.sendActivationEmail.emit();
  }
}
