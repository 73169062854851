import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment';

import { environment } from '../../../environments/environment';
import { ProductsCountOfBrand } from '../models/products-count-of-brand';
import { PriceCollectorAuditLog, PriceCollectorAuditLogResponse } from '../models/price-collector-audit-log';
import { PriceCollectorAuditLogFilter } from '../models/price-collector-audit-log-filter';
import { PriceStatistic } from '../models/price-statistic';

@Injectable()
export class ReportService {
  constructor(private http: HttpClient) {
  }

  getProductStatistic(): Observable<Array<ProductsCountOfBrand>> {
    const url = `${environment.backendUrl}/api/v1/report/product-statistic`;

    return this.http.get<Array<ProductsCountOfBrand>>(url);
  }

  getPriceCollectorAuditLog(filter: PriceCollectorAuditLogFilter): Observable<PriceCollectorAuditLogResponse> {
    const url = `${environment.backendUrl}/api/v1/report/price-collector-audit-log`;

    const params = new HttpParams()
      // tslint:disable-next-line: newline-per-chained-call
      .set('startDate', moment(filter.startDate).startOf('day').format('YYYY-MM-DD'))
      // tslint:disable-next-line: newline-per-chained-call
      .set('endDate', moment(filter.endDate).endOf('day').format('YYYY-MM-DD'))
      .set('limit', filter.count.toString())
      .set('offset', filter.offset.toString());

    return this.http
      .get<any>(url, { params })
      .pipe(
        map(data => {
          return {
            count: data.count,
            rows: data.rows.map(priceCollectorAuditLogMapper)
          };
        })
      );
  }

  getPriceStatistic(): Observable<PriceStatistic> {
    const url = `${environment.backendUrl}/api/v1/report/price-statistic`;

    return this.http
      .get<PriceStatistic>(url);
  }
}

export const priceCollectorAuditLogMapper = (data: any): Array<PriceCollectorAuditLog> => {
  return {
    ...data,
    date: new Date(data.date)
  };
};
