import { IMultiSelectOption } from 'angular-2-dropdown-multiselect';

import { Organization } from '../models/organization';
import {
  ADD_ORG,
  CANCEL_EDIT_ORG,
  EDIT_ORG, EditOrgAction,
  LIST_ORG,
  LIST_ORG_FAILED,
  LIST_ORG_SUCCESS,
  ListOrgFailedAction,
  ListOrgSuccessAction,
  OrgActions,
  SAVE_ORG,
  SAVE_ORG_FAILED,
  SAVE_ORG_SUCCESS,
  SaveOrgFailedAction,
  SaveOrgSuccessAction
} from '../actions/org.actions';

export interface State {
  orgList: Array<Organization>;
  loading: boolean;
  error?: string;
  saving?: boolean;
  editingOrg?: Organization;
}

export const initialState: State = {
  orgList: [],
  loading: true
};

export function reducer(state = initialState, action: OrgActions): State {
  switch (action.type) {

    case LIST_ORG:
      return {
        orgList: [],
        loading: true
      };

    case LIST_ORG_SUCCESS:
      return {
        loading: false,
        error: null,
        orgList: (action as ListOrgSuccessAction).payload
      };

    case LIST_ORG_FAILED:
      return {
        ...state,
        loading: false,
        error: (action as ListOrgFailedAction).payload
      };

    case EDIT_ORG:
      return {
        ...state,
        editingOrg: (action as EditOrgAction).payload
      };

    case ADD_ORG:
      return {
        ...state,
        editingOrg: {
          name: ''
        }
      };

    case CANCEL_EDIT_ORG:
      return {
        ...state,
        editingOrg: null
      };

    case SAVE_ORG:
      return {
        ...state,
        saving: true,
        error: null
      };

    case SAVE_ORG_SUCCESS: {
      const newState = {
        ...state,
        saving: false
      };

      const org = (action as SaveOrgSuccessAction).payload;

      newState.orgList = newState.editingOrg.id
        ? newState.orgList.map(x => x.id === org.id ? org : x)
        : [...newState.orgList, org];

      newState.editingOrg = null;

      return newState;
    }

    case SAVE_ORG_FAILED:
      return {
        ...state,
        error: (action as SaveOrgFailedAction).payload,
        saving: false
      };
    default:
      return state;
  }
}

export const getOrgList = (state: State) => state.orgList;
export const getEditingOrg = (state: State) => state.editingOrg;
export const getOrgMultiSelectList = (state: State): Array<IMultiSelectOption> => state.orgList
  .map(x => {
    return {
      id: x.id,
      name: x.name
    };
  })
  .sort((a, b) => a.name.localeCompare(b.name));
