import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { ActivatedRoute, Router } from '@angular/router';
import { map, tap } from 'rxjs/operators';

import { AddToUrlQueryParamsAction, AppActionTypes } from '../actions/app.actions';

@Injectable()
export class AppEffects {

  @Effect({ dispatch: false })
  addToUrlQueryParams$ = this.actions$
    .pipe(
      ofType<AddToUrlQueryParamsAction>(AppActionTypes.AddToUrlQueryParams),
      map(action => action.payload),
      tap(queryParams => this._router.navigate([],
        {
          relativeTo: this._route,
          queryParams,
          queryParamsHandling: 'merge',
          fragment: this._route.snapshot.fragment
        }
      ))
    );

  constructor(private actions$: Actions,
              private _router: Router,
              private _route: ActivatedRoute) {
  }
}
