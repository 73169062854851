import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ExistingProvider, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { noop } from 'rxjs';

import { UploadImageService } from '../services/upload-image.service';
import { httpErrorHandler } from '../../shared/http-error-handler';

const IMAGE_EDIT_ACCESSOR: ExistingProvider = {
  provide: NG_VALUE_ACCESSOR,
  // tslint:disable-next-line: no-forward-ref
  useExisting: forwardRef(() => ImageEditComponent),
  multi: true
};

@Component({
  selector: 'dpc-image-edit',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [IMAGE_EDIT_ACCESSOR],
  template: `

    <div class="image-edit">

      <label class="btn-file text-center"
             *ngIf="progress">
        <div class="image-placeholder">
          <span class="fa fa-4x fa-spin fa-spinner">
          </span>
        </div>
      </label>

      <label class="btn-file"
             *ngIf="!progress && !imageSrc">
        <div class="image-placeholder">
          <span>
            Drop an image here <br>
            - OR - <br>
            Click to browse
          </span>
        </div>

        <input type="file"
               accept="image/*"
               [disabled]="disabled"
               (change)="changeFile($event)"/>
      </label>

      <div class="btn-file"
           *ngIf="!progress && !!imageSrc">
        <img class="img-fluid"
             [dpcDynamicImage]="imageSrc"/>
        <input type="file"
               accept="image/*"
               [disabled]="disabled"
               (change)="changeFile($event)"/>
      </div>

      <div class="alert alert-danger" role="alert" *ngIf="error">
        {{ error }}
      </div>

    </div>
  `
})
export class ImageEditComponent implements ControlValueAccessor {
  imageSrc: string;
  progress: boolean;
  error: string;
  disabled: boolean;

  private onChangeCallback: (_: any) => void = noop;
  private onTouchedCallback: () => void = noop;

  constructor(private cdRef: ChangeDetectorRef,
              private imageService: UploadImageService) {
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    if (obj === this.imageSrc)
      return;

    this.imageSrc = obj;
    this.cdRef.markForCheck();
  }

  changeFile(event): void {
    const files = event.srcElement.files;

    if (files.length === 0)
      return;

    this.progress = true;
    this.imageService
      .upload(files[0])
      .subscribe((response: string) => {
          this.progress = false;
          this.imageSrc = response;
          this.onChangeCallback(this.imageSrc);
          this.cdRef.markForCheck();
        },
        errorResponse => {
          this.progress = false;
          this.error = httpErrorHandler(errorResponse);
          this.cdRef.markForCheck();
        });
  }
}
