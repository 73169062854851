import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ChartData, ChartOptions } from 'chart.js';
import * as moment from 'moment';

import { State } from '../reducers/price-statistic.reducer';
import { PriceStatistic } from '../models/price-statistic';
import { DATE_FORMAT, formatNumber } from '../../common/util';
import { COLORS } from '../../shared/colors';

@Component({
  selector: 'dpc-price-statistic',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <h3>Price statistic</h3>

    <div class="chart-container">
      <p-chart type="line"
               [data]="data"
               [options]="options"></p-chart>
    </div>
  `
})
export class PriceStatisticComponent implements OnChanges {
  @Input() state: State;

  data: ChartData;
  options: ChartOptions = {
    scales: {
      yAxes: [{
        ticks: {
          callback: value => {
            return formatNumber(value, { decimalPlaces: 0 });
          }
        }
      }]
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          return formatNumber(tooltipItem.yLabel, { decimalPlaces: 0 });
        }
      }
    }
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.state) {
      this.data = {
        labels: [],
        datasets: [
          {
            label: 'Prices',
            data: [],
            fill: false,
            backgroundColor: COLORS[0],
            borderColor: COLORS[0]
          },
          {
            label: 'Price collecting',
            data: [],
            fill: false,
            backgroundColor: COLORS[1],
            borderColor: COLORS[1]
          }
        ]
      };

      const priceStat: PriceStatistic = changes.state.currentValue.statistic;
      for (let i = 0; i < priceStat.prices.length; i++) {
        const price = priceStat.prices[i];
        const collecting = priceStat.collectors[i] || { value: null };

        this.data.labels.push(moment(price.date)
          .format(DATE_FORMAT));

        this.data.datasets[0].data[i] = price.value;
        if (collecting)
          this.data.datasets[1].data[i] = collecting.value;
      }
    }
  }

}
