import { Component } from '@angular/core';

@Component({
  selector: 'dpc-root',
  template: `
    <div class="wrapper">
      <router-outlet></router-outlet>

      <notifier-container></notifier-container>
    </div>
  `
})
export class AppComponent {
}
