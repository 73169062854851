import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'dpc-card-select-checkbox',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="card-select-checkbox"
         (click)="clicked($event)">
      <span class="daikin-pipa-icon"
            *ngIf="checked"></span>
    </div>
  `
})

export class DpcCardSelectCheckboxComponent {
  @Input()
  checked: boolean;

  @Output()
  checkedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  clicked($event: Event): void {
    $event.preventDefault();
    $event.stopPropagation();
    this.checkedChange.emit(!this.checked);
  }
}
