import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, EventEmitter,
  ExistingProvider,
  forwardRef, Output
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IMultiSelectOption, IMultiSelectSettings } from 'angular-2-dropdown-multiselect';
import { noop } from 'rxjs';

const STANDARD_OPTION_NO_ACCESSOR: ExistingProvider = {
  provide: NG_VALUE_ACCESSOR,
  // tslint:disable-next-line: no-forward-ref
  useExisting: forwardRef(() => DpcStandardOptionNoComponent),
  multi: true
};

@Component({
  selector: 'dpc-standard-option-no',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [STANDARD_OPTION_NO_ACCESSOR],
  template: `
    <ss-multiselect-dropdown [options]="options"
                             [ngModel]="model"
                             [disabled]="disabled"
                             (ngModelChange)="onChange($event)"
                             (dropdownClosed)="dropdownClosed.emit()"
                             [settings]="settings"></ss-multiselect-dropdown>
  `
})
export class DpcStandardOptionNoComponent implements ControlValueAccessor {
  @Output() dropdownClosed = new EventEmitter();

  options: Array<IMultiSelectOption> = [
    {
      id: 'STANDARD',
      name: 'STANDARD'
    },
    {
      id: 'OPTION',
      name: 'OPTION'
    },
    {
      id: 'NO',
      name: 'NO'
    }
  ];

  settings: IMultiSelectSettings = {
    enableSearch: true,
    buttonClasses: 'form-control btn btn-light',
    containerClasses: '',
    ...SINGLE_SELECT_SETTINGS
  };

  model: Array<string>;
  disabled = false;

  private onChangeCallback: (_: any) => void = noop;
  private onTouchedCallback: () => void = noop;

  constructor(private cdr: ChangeDetectorRef) {

  }

  writeValue(obj: any): void {
    if (this.model === obj)
      return;

    if (obj === null || obj === undefined)
      return;

    this.model = obj;
    this.cdr.markForCheck();
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onChange($event): void {
    this.onChangeCallback($event);
  }
}

const SINGLE_SELECT_SETTINGS = {
  selectionLimit: 1,
  autoUnselect: true
};
