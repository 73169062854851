import { PriceStatistic } from '../models/price-statistic';
import { ReportActions, ReportActionTypes } from '../actions/report.actions';

export interface State {
  statistic: PriceStatistic;
  loading: boolean;
  error?: string;
}

export const getDefaultStatistic = () => ({
  collectors: [],
  prices: []
});

export const initialState: State = {
  statistic: getDefaultStatistic(),
  loading: false
};

export function reducer(state: State = initialState, action: ReportActions): State {
  switch (action.type) {

    case ReportActionTypes.LoadPriceStatistic:
      return {
        loading: true,
        statistic: getDefaultStatistic()
      };

    case ReportActionTypes.LoadPriceStatisticSuccess:
      return {
        ...state,
        loading: false,
        statistic: action.payload
      };

    case ReportActionTypes.LoadPriceStatisticFailed:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    default:
      return state;
  }
}
