import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import { State } from '../reducers/app.reducer';
import * as fromApp from '../reducers';
import * as fromAuth from '../../auth/reducers';
import { ToggleAdminMenuAction, ToggleSideMenuAction } from '../actions/app.actions';
import { Logout } from '../../auth/actions/auth.actions';

@Component({
  selector: 'dpc-secure-root',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="d-flex flex-column">
      <dpc-header [state]="state"
                  [headerLogoSrc]="headerLogoSrc$ | async"
                  (toggleSideMenu)="onToggleSideMenu()"
                  (logout)="onLogout()"></dpc-header>

      <div class="d-flex align-content-stretch">
        <aside class="side-menu-bg"
               [class.opened]="state.sideMenuVisible"
               [class.closed]="!state.sideMenuVisible">
          <dpc-side-menu [state]="state"
                         [userIsAdmin]="userIsAdmin$ | async"
                         (toggleAdminMenu)="onToggleAdminMenu()"></dpc-side-menu>
        </aside>
        <main>
          <div class="d-block w-100 h-100">
            <router-outlet></router-outlet>
          </div>
        </main>
      </div>
    </div>
  `
})
export class SecureRootComponent implements OnInit, OnDestroy {
  state: State;
  headerLogoSrc$: Observable<string>;
  userIsAdmin$: Observable<boolean>;

  private _stateSubscription: Subscription;

  constructor(private _store: Store<fromApp.State>,
              private _cdRef: ChangeDetectorRef) {

  }

  ngOnInit(): void {
    this._stateSubscription = this._store.select(fromApp.getAppState)
      .subscribe(data => {
        this.state = data;
        this._cdRef.markForCheck();
      });

    this.headerLogoSrc$ = this._store.select(fromApp.getHeaderLogoSrc);
    this.userIsAdmin$ = this._store.select(fromAuth.isAdminUser);
  }

  ngOnDestroy(): void {
    if (this._stateSubscription)
      this._stateSubscription.unsubscribe();
  }

  onToggleSideMenu(): void {
    this._store.dispatch(new ToggleSideMenuAction());
  }

  onToggleAdminMenu(): void {
    this._store.dispatch(new ToggleAdminMenuAction());
  }

  onLogout(): void {
    this._store.dispatch(new Logout());
  }
}
