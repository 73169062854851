import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class JsonInterceptorService implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.responseType !== 'json')
      return next.handle(req);

    req = req.clone({
      responseType: 'text'
    });

    return next.handle(req)
      .pipe(
        map(event => {
          if (!(event instanceof HttpResponse))
            return event;

          const response: HttpResponse<any> = event;

          let body;

          if (response.body && typeof response.body === 'string')
            body = JSON.parse(response.body, dataParser);

          return response.clone({ body });
        })
      );
  }
}

const DATE_FORMAT = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?Z$/;

const dataParser = (key: any, value: any): any => {
  if (typeof value === 'string' && DATE_FORMAT.test(value))
    return new Date(value);

  return value;
};
