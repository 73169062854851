import * as auth from '../actions/auth.actions';
import { User } from '../models/user';

export interface State {
  loggedIn: boolean;
  user: User | null;
}

export const initialState: State = {
  loggedIn: false,
  user: {
    orgs: []
  }
};

export function reducer(state = initialState, action: auth.Actions): State {
  switch (action.type) {
    case auth.LOGIN_SUCCESS: {
      return {
        ...state,
        loggedIn: true,
        user: action.payload
      };
    }

    case auth.LOGOUT:
      return {
        ...state,
        loggedIn: false,
        user: {
          orgs: []
        }
      };

    default: {
      return state;
    }
  }
}

export const getLoggedIn = (state: State) => state.loggedIn;
export const getUser = (state: State) => state.user;
export const isAdminUser = (state: State) => state.user && state.user.role === 'admin';
export const isEditorUser = (state: State) => state.user && (state.user.role === 'admin' || state.user.role === 'editor');
