import cloneDeep from 'lodash-es/cloneDeep';

import * as auth from '../actions/change-password.actions';

export interface State {
  error: string | null;
  pending: boolean;
  message: string;
}

export const initialState: State = {
  error: null,
  pending: false,
  message: null
};

export function reducer(state = initialState, action: auth.Actions): State {
  switch (action.type) {
    case auth.INIT_CHANGE_PASSWORD_PAGE:
      return cloneDeep(initialState);

    case auth.CHANGE_PASSWORD:
      return {
        ...state,
        error: null,
        message: null,
        pending: true
      };

    case auth.CHANGE_PASSWORD_FAILED: {
      return {
        ...state,
        error: (action as auth.ChangePasswordFailedAction).payload,
        pending: false
      };
    }

    case auth.CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        error: null,
        pending: false,
        message: 'Change password successful'
      };
    }

    default: {
      return state;
    }
  }
}

export const getState = (state: State) => state;
