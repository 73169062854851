import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import * as fromAuth from '../reducers';
import { State as PageState } from '../reducers/activate-user.reducer';
import { ActivateUserAction, RouteTokenChangedAction } from '../actions/activate-user.actions';
import { ActivateUser } from '../models/activate-user';

@Component({
  selector: 'dpc-activate-user-page',
  template: `
    <div class="row justify-content-md-center">
      <div class="col-md-6">
        <h1 class="text-center">Activate Account</h1>
      </div>
    </div>

    <dpc-activate-user-form [state]="pageState$ | async"
                            (submitted)="onSubmit($event)"></dpc-activate-user-form>
  `
})
export class ActivateUserPageComponent implements OnInit, OnDestroy {

  pageState$: Observable<PageState>;

  private routeChangeSubscription: Subscription;

  constructor(private _store: Store<fromAuth.State>,
              private route: ActivatedRoute) {
    this.pageState$ = _store.select(fromAuth.getActivateUserPage);
  }

  ngOnInit(): void {
    this.routeChangeSubscription = this.route.params.subscribe((params: Params) => {
      if (!params)
        return;

      const token = params['activationToken'];
      this._store.dispatch(new RouteTokenChangedAction(token));
    });
  }

  ngOnDestroy(): void {
    if (this.routeChangeSubscription)
      this.routeChangeSubscription.unsubscribe();
  }

  onSubmit(data: ActivateUser): void {
    this._store.dispatch(new ActivateUserAction(data));
  }
}
