import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as fromAuth from '../reducers';
import { State as PageState } from '../reducers/forgot-password.reducer';
import { ForgotPasswordAction } from '../actions/forgot-password.actions';

@Component({
  selector: 'dpc-forgot-password-page',
  template: `
    <div class="row justify-content-md-center">
      <div class="col-md-6">
        <h1 class="text-center">Forgot Password</h1>
      </div>
    </div>

    <dpc-forgot-password-form [state]="pageState$ | async"
                              (submitted)="onSubmit($event)">
    </dpc-forgot-password-form>
  `
})
export class ForgotPasswordPageComponent {

  pageState$: Observable<PageState>;

  constructor(private _store: Store<fromAuth.State>) {
    this.pageState$ = _store.select(fromAuth.getForgotPasswordPage);
  }

  onSubmit(email: string): void {
    this._store.dispatch(new ForgotPasswordAction(email));
  }
}
