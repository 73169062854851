import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Organization } from '../models/organization';
import { environment } from '../../../environments/environment';
import { User } from '../../auth/models/user';

@Injectable()
export class OrgService {
  constructor(private http: HttpClient) {
  }

  get(id: number): Observable<Organization> {
    const url = `${environment.backendUrl}/api/v1/organization/${id}`;

    return this.http.get<Organization>(url);
  }

  list(): Observable<Array<Organization>> {
    const url = `${environment.backendUrl}/api/v1/organization`;

    return this.http.get<Array<Organization>>(url);
  }

  add(org: Organization): Observable<Organization> {
    const url = `${environment.backendUrl}/api/v1/organization`;

    return this.http.put<Organization>(url, org);
  }

  save(org: Organization): Observable<Organization> {
    const url = `${environment.backendUrl}/api/v1/organization/${org.id}`;

    return this.http.post<Organization>(url, org);
  }

  addUser(user: User): Observable<User> {
    const url = `${environment.backendUrl}/api/v1/organization/${user.orgId}/user`;

    return this.http.post<User>(url, user);
  }

  deleteUser(user: User): Observable<any> {
    const url = `${environment.backendUrl}/api/v1/user/${user.id}`;

    return this.http.delete(url);
  }

  listUser(): Observable<Array<User>> {
    const url = `${environment.backendUrl}/api/v1/user`;

    return this.http.get<Array<User>>(url);
  }

  sendUserActivationEmail(user: User): Observable<void> {
    const url = `${environment.backendUrl}/user-api/v1/${user.id}/resend-activation-email`;

    return this.http.post<void>(url, undefined);
  }
}
