import { HttpErrorResponse } from '@angular/common/http';

export function httpErrorHandler(err: HttpErrorResponse): any {
  if (err.error instanceof Error)
  // client side error
    return err.error;
  else if (err.error)
    return parseErrorResponse(err.error);
  else if (err.status === 500)
    return new Error('Internal Server Error');
  else
    return new Error(err.error.message);
}

function parseErrorResponse(error: any): any {
  if (typeof error !== 'string')
    return error;

  try {
    return JSON.parse(error);
  } catch {
    return error;
  }
}
