import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';

import { FEATURE_NAME, reducers } from './reducers';
import { OrgListPageComponent } from './containers/org-list-page.component';
import { OrgListComponent } from './components/org-list.component';
import { EffectsModule } from '@ngrx/effects';
import { OrgEffects } from './effects/org.effects';
import { OrgService } from './services/org.service';
import { DpcCommonModule } from '../common/common.module';
import { UserListComponent } from './components/user-list.component';
import { CurrenciesEffects } from './effects/currency.effects';
import { CurrencyService } from './services/currency.service';
import { OrgNamePipe } from './pipes/org-name.pipe';
import { UserListPageComponent } from './containers/user-list-page.component';
import { UserEffects } from './effects/user.effects';
import { SendEmaiActivationComponent } from './components/send-emai-activation.component';

const COMPONENTS = [
  OrgListPageComponent,
  OrgListComponent,
  UserListComponent,
  UserListPageComponent,
  OrgNamePipe,
  SendEmaiActivationComponent,
];

@NgModule({
  declarations: COMPONENTS,
  exports: COMPONENTS,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    DpcCommonModule,
    ConfirmationPopoverModule,
    StoreModule.forFeature(FEATURE_NAME, reducers),
    EffectsModule.forFeature([
      OrgEffects,
      CurrenciesEffects,
      UserEffects
    ])
  ]
})
export class OrgModule {
  static forRoot(): ModuleWithProviders<OrgModule> {
    return {
      ngModule: OrgModule,
      providers: [
        OrgService,
        CurrencyService
      ]
    };
  }
}
