import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { User } from '../../auth/models/user';
import * as fromOrg from '../reducers';
import { Store } from '@ngrx/store';
import {
  AddUserAction,
  CancelEditUserAction,
  DeleteUserAction,
  EditUserAction,
  LoadUsersAction,
  SaveUserAction,
  SendActivationEmailAction
} from '../actions/user.actions';
import { ListOrgAction } from '../actions/org.actions';
import { Organization } from '../models/organization';

@Component({
  selector: 'dpc-user-list-page',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <h1 class="page-header">
      Manage Users
    </h1>

    <dpc-org-user-list [users]="userList$ | async"
                       [editingUser]="editingUser$ | async"
                       [allowAddingOrEditingUser]="allowAddingOrEditingUser$ | async"
                       [orgs]="orgList$ | async"
                       (editUser)="onEditUser($event)"
                       (cancelEditUser)="onCancelEdit()"
                       (addUser)="onAddUser()"
                       (saveUser)="onSaveUser($event)"
                       (deleteUser)="onDeleteUser($event)"
                       (sendActivationEmail)="onSendActivationEmail($event)"
    ></dpc-org-user-list>
  `
})

export class UserListPageComponent implements OnInit {
  userList$: Observable<Array<User>>;
  allowAddingOrEditingUser$: Observable<boolean>;
  editingUser$: Observable<User>;
  orgList$: Observable<Array<Organization>>;

  constructor(private _store: Store<fromOrg.State>) {
  }

  ngOnInit(): void {
    this._store.dispatch(new LoadUsersAction());
    this._store.dispatch(new ListOrgAction());
    this.userList$ = this._store.select(fromOrg.getUserList);
    this.editingUser$ = this._store.select(fromOrg.getEditing);
    this.allowAddingOrEditingUser$ = this._store.select(fromOrg.allowAddingOrEditingUser);
    this.orgList$ = this._store.select(fromOrg.getOrgMultiSelectList);
  }

  onEditUser($event: User): void {
    this._store.dispatch(new EditUserAction($event));
  }

  onDeleteUser($event: User): void {
    this._store.dispatch(new DeleteUserAction($event));
  }

  onCancelEdit(): void {
    this._store.dispatch(new CancelEditUserAction());
  }

  onAddUser(): void {
    this._store.dispatch(new AddUserAction());
  }

  onSaveUser($event: User): void {
    this._store.dispatch(new SaveUserAction($event));
  }

  onSendActivationEmail($event: User): void {
    this._store.dispatch(new SendActivationEmailAction($event));
  }
}
