import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromOrg from '../reducers';
import { AddOrgAction, CancelEditOrgAction, EditOrgAction, ListOrgAction, SaveOrgAction } from '../actions/org.actions';
import { Observable } from 'rxjs';
import { Organization } from '../models/organization';
import { IMultiSelectOption } from 'angular-2-dropdown-multiselect';
import { LoadCurrenciesAction } from '../actions/currency.actions';

@Component({
  selector: 'dpc-org-list-page',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <h1 class="page-header">
      Manage Organizations
    </h1>

    <dpc-org-list [orgList]="orgList$ | async"
                  [parentOrgList]="parentOrgList$ | async"
                  [currencies]="currencies$ | async"
                  [editingOrg]="editingOrg$ | async"
                  [hasRightToAddEditOrg]="hasRightToAddEditOrg$ | async"
                  (addNewOrganization)="addNewOrganization()"
                  (editOrganization)="editOrg($event)"
                  (cancelEditOrganization)="cancelEditOrg()"
                  (saveOrganization)="saveOrganization($event)"></dpc-org-list>
  `
})
export class OrgListPageComponent implements OnInit {
  orgList$: Observable<Array<Organization>>;
  parentOrgList$: Observable<Array<Organization>>;
  currencies$: Observable<Array<IMultiSelectOption>>;
  editingOrg$: Observable<Organization>;
  hasRightToAddEditOrg$: Observable<boolean>;

  constructor(private _store: Store<fromOrg.State>) {
    this.orgList$ = _store.select(fromOrg.getOrgList);
    this.editingOrg$ = _store.select(fromOrg.getEditingOrg);
    this.hasRightToAddEditOrg$ = _store.select(fromOrg.hasRightToAddEditOrg);
    this.parentOrgList$ = _store.select(fromOrg.getParentOrgList);
    this.currencies$ = this._store.select(fromOrg.getCurrencyList);
  }

  ngOnInit(): void {
    this._store.dispatch(new ListOrgAction());
    this._store.dispatch(new LoadCurrenciesAction());
  }

  addNewOrganization(): void {
    this._store.dispatch(new AddOrgAction());
  }

  editOrg($event: Organization): void {
    this._store.dispatch(new EditOrgAction($event));
  }

  cancelEditOrg(): void {
    this._store.dispatch(new CancelEditOrgAction());
  }

  saveOrganization(org: Organization): void {
    this._store.dispatch(new SaveOrgAction(org));
  }
}
