import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export const dateToDpObject = (date: Date): NgbDateStruct | null => {
  if (!date || !(date instanceof Date)) return null;

  return {
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate()
  };
};

export const dpObjectToDate = (data: NgbDateStruct): Date => {
  if (!data || !data.year) return null;

  const d = new Date(data.year, (data.month || 1) - 1, data.day || 1);

  return d;
};
