import { Injectable } from '@angular/core';
import { Action, select, Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { defer, EMPTY, Observable, of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { OrgService } from '../services/org.service';
import {
  LIST_ORG,
  ListOrgAction,
  ListOrgFailedAction,
  ListOrgSuccessAction,
  LOAD_ORG,
  LoadOrgAction,
  LoadOrgFailedAction,
  LoadOrgSuccessAction,
  SAVE_ORG,
  SaveOrgAction,
  SaveOrgFailedAction,
  SaveOrgSuccessAction
} from '../actions/org.actions';
import { httpErrorHandler } from '../../shared/http-error-handler';
import { Organization } from '../models/organization';
import * as fromOrg from '../reducers';
import { getUser } from '../../auth/reducers';

@Injectable()
export class OrgEffects {

  @Effect()
  listOrgs$ = this.actions$.pipe(
    ofType<ListOrgAction>(LIST_ORG),
    withLatestFrom(this._store.pipe(select(getUser))),
    switchMap(([action, user]) => {
        if (!user || !user.id)
          return EMPTY;

        return this.orgService.list()
          .pipe(
            map(orgList => new ListOrgSuccessAction(orgList)),
            catchError(err => {
              const error = httpErrorHandler(err);

              return of(new ListOrgFailedAction(error.message));
            })
          );
      }
    )
  );

  @Effect()
  saveOrg$ = this.actions$.pipe(
    ofType<SaveOrgAction>(SAVE_ORG),
    map(action => action.payload),
    switchMap(org => this.saveOrg(org))
  );

  @Effect()
  loadOrg$ = this.actions$.pipe(
    ofType<LoadOrgAction>(LOAD_ORG),
    map(action => action.payload),
    switchMap(id => this.orgService.get(id)
      .pipe(
        map(org => new LoadOrgSuccessAction(org)),
        catchError(err => {
          const error = httpErrorHandler(err);

          return of(new LoadOrgFailedAction(error.message));
        })
      )
    )
  );

  @Effect()
  init$: Observable<Action> = defer(() => of(new ListOrgAction()));

  constructor(private actions$: Actions,
              private orgService: OrgService,
              private _store: Store<fromOrg.State>) {
  }

  private saveOrg(org: Organization): Observable<SaveOrgFailedAction | SaveOrgSuccessAction> {
    const command = org.id ? this.orgService.save(org) : this.orgService.add(org);

    return command.pipe(
      map(savedOrg => new SaveOrgSuccessAction(savedOrg)),
      catchError(err => {
        const error = httpErrorHandler(err);

        return of(new SaveOrgFailedAction(error.message));
      })
    );
  }
}
