import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { User } from '../../auth/models/user';
import cloneDeep from 'lodash-es/cloneDeep';
import { Organization } from '../models/organization';

@Component({
  selector: 'dpc-org-user-list',
  template: `
    <div class="container-fluid">
      <div class="row mt-3">
        <div class="col-md-12">
          <table class="daikin-table">
            <thead>
              <tr>
                <th>E-mail</th>
                <th>Organization</th>
                <th>Role</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let user of users">

                <ng-container *ngIf="user.id !== editingUser?.id">
                  <ng-container *ngTemplateOutlet="readonlyTemplate; context: {user: user}"></ng-container>
                </ng-container>

                <ng-container *ngIf="user.id === editingUser?.id">
                  <ng-container *ngTemplateOutlet="editTemplate"></ng-container>
                </ng-container>

              </ng-container>

              <ng-container *ngIf="editingUser && !editingUser.id">
                <ng-container *ngTemplateOutlet="editTemplate"></ng-container>
              </ng-container>

            </tbody>
          </table>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 mt-3">
          <button *ngIf="allowAddingOrEditingUser"
                  class="btn btn-primary"
                  type="button"
                  (click)="addUser.emit()">Add User
          </button>
        </div>
      </div>
    </div>

    <ng-template #readonlyTemplate let-user="user">
      <tr>
        <td>{{ user.email }}</td>
        <td>
          {{ user.orgId | dpcOrgName }}
        </td>
        <td>
          {{ user.role }}
        </td>
        <td>
          <button type="button"
                  *ngIf="allowAddingOrEditingUser"
                  class="btn btn-link btn-icon"
                  (click)="editUser.emit(user)">
            <span class="fa fa-edit text-primary"></span>
          </button>

          <button type="button"
                  class="btn btn-link btn-icon"
                  *ngIf="allowAddingOrEditingUser"
                  mwlConfirmationPopover
                  placement="bottom"
                  popoverTitle="Are you sure delete this user?"
                  (confirm)="deleteUser.emit(user)">
            <span class="fa fa-trash text-danger"></span>
          </button>

          <dbp-send-activation-email *ngIf="!user.isActivated" [sending]="user.sendingActivationEmail"
                                     (sendActivationEmail)="sendActivationEmail.emit(user)"
          ></dbp-send-activation-email>
        </td>
      </tr>
    </ng-template>

    <ng-template #editTemplate let-user="user">
      <tr [formGroup]="form">
        <td>
          <input type="text"
                 class="form-control"
                 placeholder="E-mail"
                 formControlName="email"/>
        </td>
        <td>
          <dpc-select id="parentOrg"
                      formControlName="orgId"
                      [options]="orgs"></dpc-select>
        </td>
        <td>
          <select class="form-control"
                  formControlName="role">
            <option value="readonly">Readonly</option>
            <option value="editor">Editor</option>
            <option value="admin">Admin</option>
          </select>
        </td>
        <td>
          <button type="button"
                  class="btn btn-link btn-icon"
                  (click)="cancelEditUser.emit()">
            <span class="fa fa-times-circle text-danger"></span>
          </button>

          <button type="submit"
                  class="btn btn-link btn-icon"
                  [disabled]="form.invalid"
                  (click)="onSubmit()">
            <span class="fa fa-check-circle text-secondary"></span>
          </button>
        </td>
      </tr>
    </ng-template>
  `
})

export class UserListComponent implements OnChanges {
  @Input() users: Array<User>;
  @Input() editingUser: User;
  @Input() allowAddingOrEditingUser: boolean;
  @Input() orgs: Array<Organization>;

  @Output() addUser = new EventEmitter();
  @Output() saveUser = new EventEmitter<User>();
  @Output() editUser = new EventEmitter<User>();
  @Output() deleteUser = new EventEmitter<User>();
  @Output() cancelEditUser = new EventEmitter();
  @Output() sendActivationEmail = new EventEmitter<User>();

  form = new FormGroup({
    email: new FormControl('', [Validators.required]),
    orgId: new FormControl('', [Validators.required]),
    role: new FormControl()
  });

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['editingUser'] && changes['editingUser'].currentValue) {
      const value = cloneDeep(changes['editingUser'].currentValue);

      value.orgId = value.orgId ? [value.orgId] : [];

      this.form.patchValue(value);
    }
  }

  onSubmit(): void {
    if (!this.form.valid)
      return;

    const value: User = {
      ...this.editingUser,
      ...this.form.value
    };

    if (value.orgId)
      value.orgId = value.orgId[0];

    this.saveUser.emit(value);
  }
}
