import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';
import { NotifierModule } from 'angular-notifier';
import { JwtModule } from '@auth0/angular-jwt';
import { DragulaModule } from 'ng2-dragula';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { AppComponent } from './core/containers/app.component';
import { CoreModule } from './core/core.module';
import { appRoutes } from './app.routes';
import { metaReducers, reducers } from './reducers';
import { CustomRouterStateSerializer } from './shared/utils';
import { AuthModule } from './auth/auth.module';
import { WindowRef } from './shared/window-ref';
import { OrgModule } from './org/org.module';
import { DpcCommonModule } from './common/common.module';
import { angularNotifierConfig } from './shared/angular-notifier-config';
import { ApplicationEffects } from './effects/app.effects';
import { ReportModule } from './report/report.module';
import { environment } from '../environments/environment';

export function tokenGetter(): string {
  return localStorage.getItem(environment.JwtTokenId);
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes),
    /**
     * StoreModule.forRoot is imported once in the root module, accepting a reducer
     * function or object map of reducer functions. If passed an object of
     * reducers, combineReducers will be run creating your application
     * meta-reducer. This returns all providers for an @ngrx/store
     * based application.
     */
    StoreModule.forRoot(reducers, { metaReducers }),
    /**
     * @ngrx/router-store keeps router state up-to-date in the store.
     */
    StoreRouterConnectingModule.forRoot(),
    /**
     * Store devtools instrument the store retaining past versions of state
     * and recalculating new states. This enables powerful time-travel
     * debugging.
     *
     * To use the debugger, install the Redux Devtools extension for either
     * Chrome or Firefox
     *
     * See: https://github.com/zalmoxisus/redux-devtools-extension
     */
    StoreDevtoolsModule.instrument(),
    /**
     * EffectsModule.forRoot() is imported once in the root module and
     * sets up the effects class to be initialized immediately when the
     * application starts.
     *
     * See: https://github.com/ngrx/platform/blob/master/docs/effects/api.md#forroot
     */
    NgbModule,
    EffectsModule.forRoot([ApplicationEffects]),
    CoreModule.forRoot(),
    ConfirmationPopoverModule.forRoot({
      confirmButtonType: 'danger' // set defaults here
    }),
    NotifierModule.withConfig(angularNotifierConfig),
    AuthModule.forRoot(),
    OrgModule.forRoot(),
    DpcCommonModule.forRoot(),
    ReportModule.forRoot(),
    ReportModule.forRoot(),
    JwtModule.forRoot({
      config: {
        tokenGetter
      }
    }),
    DragulaModule.forRoot()
  ],
  providers: [
    /**
     * The `RouterStateSnapshot` provided by the `Router` is a large complex structure.
     * A custom RouterStateSerializer is used to parse the `RouterStateSnapshot` provided
     * by `@ngrx/router-store` to include only the desired pieces of the snapshot.
     */
    { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },
    WindowRef
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
