import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromRoot from '../../reducers';
import * as fromProdStat from './product-statistic.reducer';
import * as fromAuditLog from './price-collector-audit-log.reducer';
import * as fromPriceStat from './price-statistic.reducer';

export const FEATURE_NAME = 'report';

export interface ReportState {
  productStatistic: fromProdStat.State;
  priceCollectorAuditLog: fromAuditLog.State;
  priceStatistic: fromPriceStat.State;
}

export interface State extends fromRoot.State {
  [FEATURE_NAME]: ReportState;
}

export const reducers: ActionReducerMap<ReportState> = {
  productStatistic: fromProdStat.reducer,
  priceCollectorAuditLog: fromAuditLog.reducer,
  priceStatistic: fromPriceStat.reducer
};

export const getReportState = createFeatureSelector<ReportState>(FEATURE_NAME);
export const getProductStatisticState = createSelector(getReportState, state => state.productStatistic);
export const getPriceStatisticState = createSelector(getReportState, state => state.priceStatistic);

export const getPriceCollectorAuditLogState = createSelector(getReportState, state => state.priceCollectorAuditLog);
export const getPriceCollectorFilter = createSelector(getPriceCollectorAuditLogState, fromAuditLog.getPriceCollectorFilter);
