import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { ReportService } from '../services/report.service';
import {
  InitPriceCollectorAuditLogAction,
  LoadPriceCollectorAuditLogAction,
  LoadPriceCollectorAuditLogFailedAction,
  LoadPriceCollectorAuditLogPageChangeAction,
  LoadPriceCollectorAuditLogSuccessAction,
  LoadPriceStatisticAction,
  LoadPriceStatisticActionSuccess,
  LoadProductStatisticAction,
  LoadProductStatisticFailedAction,
  LoadProductStatisticSuccessAction,
  ReportActionTypes
} from '../actions/report.actions';
import { getPriceCollectorFilter, State } from '../reducers';
import { PriceCollectorAuditLogFilter } from '../models/price-collector-audit-log-filter';

@Injectable()
export class ReportEffect {

  @Effect()
  loadProductStatistic$ = this.actions$.pipe(
    ofType<LoadProductStatisticAction>(ReportActionTypes.LoadProductStatistic),
    switchMap(() => this.reportService.getProductStatistic()
      .pipe(
        map(data => new LoadProductStatisticSuccessAction(data)),
        catchError(err => of(new LoadProductStatisticFailedAction(err)))
      )
    )
  );

  @Effect()
  initPriceCollectorAuditLog$ = this.actions$.pipe(
    ofType<InitPriceCollectorAuditLogAction
      | LoadPriceCollectorAuditLogAction
      | LoadPriceCollectorAuditLogPageChangeAction>(
      ReportActionTypes.InitPriceCollectorAuditLog,
      ReportActionTypes.PriceCollectorAuditLogFromChange,
      ReportActionTypes.LoadPriceCollectorPageChange),
    withLatestFrom(this.store.pipe(select(getPriceCollectorFilter))),
    map(([action, filter]) => filter),
    switchMap(filter => this.loadPriceCollectorLog(filter)
    )
  );

  @Effect()
  loadPriceStatistic$ = this.actions$.pipe(
    ofType<LoadPriceStatisticAction>(ReportActionTypes.LoadPriceStatistic),
    switchMap(() => this.reportService.getPriceStatistic()
      .pipe(
        map(data => new LoadPriceStatisticActionSuccess(data)),
        catchError(err => of(new LoadProductStatisticFailedAction(err)))
      )
    )
  );

  constructor(private actions$: Actions,
              private store: Store<State>,
              private reportService: ReportService) {
  }

  // tslint:disable-next-line: max-line-length
  private loadPriceCollectorLog(filter: PriceCollectorAuditLogFilter): Observable<LoadPriceCollectorAuditLogSuccessAction | LoadPriceCollectorAuditLogFailedAction> {
    return this.reportService.getPriceCollectorAuditLog(filter)
      .pipe(
        map(data => new LoadPriceCollectorAuditLogSuccessAction(data)),
        catchError(err => of(new LoadPriceCollectorAuditLogFailedAction(err)))
      );
  }
}
