import { Action } from '@ngrx/store';

export const LOAD_CURRENCIES = '[CURR] load currencies';
export const LOAD_CURRENCIES_SUCCESS = '[CURR] load currencies success';
export const LOAD_CURRENCIES_FAILED = '[CURR] load currencies failed';

export class LoadCurrenciesAction implements Action {
  type = LOAD_CURRENCIES;
}

export class LoadCurrenciesSuccessAction implements Action {
  type = LOAD_CURRENCIES_SUCCESS;

  constructor(public payload: Array<string>) {
  }
}

export class LoadCurrenciesFailedAction implements Action {
  type = LOAD_CURRENCIES_FAILED;

  constructor(public payload: any) {
  }
}

export type Actions =
  LoadCurrenciesAction
  | LoadCurrenciesSuccessAction
  | LoadCurrenciesFailedAction
  ;
