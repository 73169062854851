import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect';
import { DpDatePickerModule } from 'ng2-date-picker';
import { DpcSelectComponent } from './components/dpc-select.component';
import { DpcDateComponent } from './components/dpc-date.component';
import { DpcInvalidFeedbackComponent } from './components/dpc-invalid-feedback';
import { DpcYesNoComponent } from './components/dpc-yes-no.component';
import { AddOtherValueComponent } from './components/add-other-value.component';
import { ImageEditComponent } from './components/image-edit.component';
import { UploadImageService } from './services/upload-image.service';
import { DynamicImageDirective } from './components/dynamic-image.directive';
import { DpcNumberComponent } from './components/dpc-number.component';
import { NumberFormatPipe } from './pipes/number-format-pipe';
import { DpcCardSelectCheckboxComponent } from './components/dpc-card-select-checkbox.component';
import { DashPipe } from './pipes/dash.pipe';
import { DpcStandardOptionNoComponent } from './components/dpc-standard-option-no.component';

export const COMPONENTS = [
  DpcSelectComponent,
  DpcDateComponent,
  DpcInvalidFeedbackComponent,
  DpcYesNoComponent,
  AddOtherValueComponent,
  ImageEditComponent,
  DynamicImageDirective,
  DpcNumberComponent,
  NumberFormatPipe,
  DpcCardSelectCheckboxComponent,
  DashPipe,
  DpcStandardOptionNoComponent
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MultiselectDropdownModule,
    DpDatePickerModule
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS,
  entryComponents: [
    AddOtherValueComponent
  ]
})
export class DpcCommonModule {
  static forRoot(): ModuleWithProviders<DpcCommonModule> {
    return {
      ngModule: DpcCommonModule,
      providers: [
        UploadImageService
      ]
    };
  }
}
