import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as fromAuth from '../reducers';
import { State as PageState } from '../reducers/activate-user.reducer';
import { ChangePasswordAction, InitChangePasswordPageAction } from '../actions/change-password.actions';
import { ChangePasswordData } from '../models/change-password-data';

@Component({
  selector: 'dpc-change-password-page',
  template: `
    <div class="row justify-content-md-center">
      <div class="col-md-6">
        <h1 class="text-center">Change Password</h1>
      </div>
    </div>

    <dpc-change-password-form [state]="pageState$ | async"
                              (submitted)="onSubmit($event)"></dpc-change-password-form>
  `
})
export class ChangePasswordPageComponent implements OnInit {

  pageState$: Observable<PageState>;

  constructor(private _store: Store<fromAuth.State>) {
    this.pageState$ = _store.select(fromAuth.getChangePasswordPage);
  }

  ngOnInit(): void {
    this._store.dispatch(new InitChangePasswordPageAction());
  }

  onSubmit(data: ChangePasswordData): void {
    this._store.dispatch(new ChangePasswordAction(data));
  }
}
