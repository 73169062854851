import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';

import { environment } from '../../../environments/environment';
import { Authenticate } from '../models/authenticate';

@Component({
  selector: 'dpc-login-form',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
  <div class="row justify-content-md-center">
    <div class="col-md-4">
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <div class="input-group">
            <div class="input-group-prepend"><span class="input-group-text daikin-mail-icon"></span></div>
            <input type="text"
                   class="form-control"
                   id="email"
                   placeholder="E-mail"
                   formControlName="email">
          </div>
          <div class="invalid-feedback"
               *ngIf="form.controls['email'].errors?.required && (form.controls['email'].dirty || form.controls['email'].touched)">
            E-mail is required
          </div>
          <div class="invalid-feedback"
               *ngIf="form.controls['email'].errors?.email && (form.controls['email'].dirty || form.controls['email'].touched)">
            Wrong e-mail address format
          </div>
        </div>

        <div class="form-group">
          <div class="input-group">
            <div class="input-group-prepend"><span class="input-group-text daikin-lock-icon"></span></div>
            <input type="password"
                   class="form-control"
                   id="password"
                   placeholder="Password"
                   formControlName="password">
          </div>
          <div class="invalid-feedback"
               *ngIf="form.controls['password'].errors?.required && (form.controls['password'].dirty || form.controls['password'].touched)">
            Password is required
          </div>
          <div class="invalid-feedback"
               *ngIf="form.controls['password'].errors?.pattern && (form.controls['password'].dirty || form.controls['password'].touched)">
            Wrong password format.
          </div>
        </div>

        <div class="alert alert-danger" role="alert" *ngIf="error">
          {{ error }}
        </div>

      <a [routerLink]="['/auth/forgot-password']" class="btn btn-link half-fluid-button">Forgot password</a>

        <button type="submit" class="btn btn-primary half-fluid-button float-right"
                [disabled]="!form.valid || processing">Login
          <div class="animation-container" *ngIf="processing">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
          </div>
        </button>
      </form>
    </div>
  </div>
`
})
export class LoginFormComponent {

  @Input() error: string | null;

  @Input()
  set processing(isPending: boolean) {
    this._processing = isPending;
    if (isPending)
      this.form.disable();
    else
      this.form.enable();
  }

  get processing(): boolean {
    return this._processing;
  }

  @Output() submitted = new EventEmitter<Authenticate>();

  form: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, CustomValidators.email]),
    password: new FormControl('', [Validators.required, Validators.pattern(environment.passwordPattern)])
  });

  private _processing = false;

  onSubmit(): void {
    if (this.form.valid)
      this.submitted.emit(this.form.value);
  }
}
