import { User } from '../../auth/models/user';
import {
  DeleteUserSuccessAction,
  EditUserAction,
  LoadUsersFailedAction,
  LoadUsersSuccessAction,
  SaveUserSuccessAction, SendActivationEmailAction,
  UserActions,
  UserActionTypes
} from '../actions/user.actions';

export interface State {
  userList: Array<User>;
  loading: boolean;
  error?: string;
  saving?: boolean;
  editingUser?: User;
}

export const initialState: State = {
  userList: [],
  loading: false
};

export function reducer(state = initialState, action: UserActions): State {
  switch (action.type) {

    case UserActionTypes.LoadUsers:
      return {
        ...state,
        loading: true
      };

    case UserActionTypes.LoadUsersSuccess:
      return {
        ...state,
        loading: false,
        userList: (action as LoadUsersSuccessAction).payload
      };

    case UserActionTypes.LoadUsersFailed:
      return {
        ...state,
        loading: false,
        error: (action as LoadUsersFailedAction).payload
      };

    case UserActionTypes.EditUser:
      return {
        ...state,
        editingUser: (action as EditUserAction).payload
      };

    case UserActionTypes.AddUser:
      return {
        ...state,
        editingUser: {
          role: 'readonly'
        }
      };

    case UserActionTypes.CancelEditUser:
      return {
        ...state,
        editingUser: null
      };

    case UserActionTypes.DeleteUser:
    case UserActionTypes.SaveUser:
      return {
        ...state,
        saving: true
      };

    case UserActionTypes.SaveUserSuccess: {
      const newState = {
        ...state
      };

      const user = (action as SaveUserSuccessAction).payload;

      newState.userList = newState.editingUser.id
        ? newState.userList.map(x => x.id === user.id ? user : x)
        : [...newState.userList, user];

      newState.editingUser = null;

      return newState;
    }

    case UserActionTypes.DeleteUserFailed:
    case UserActionTypes.SaveUserFailed:
      return {
        ...state,
        saving: false,
        error: (action as LoadUsersFailedAction).payload
      };

    case UserActionTypes.DeleteUserSuccess:
      return {
        ...state,
        saving: false,
        userList: state.userList.filter(user => user.id !== (action as DeleteUserSuccessAction).payload.id)
      };

    case UserActionTypes.SendActivationEmail:
      return {
        ...state,
        userList: state.userList.map(user => {
          if (user.id === (action as SendActivationEmailAction).payload.id)
            return {
              ...user,
              sendingActivationEmail: true
            };

          return user;
        })
      };

    case UserActionTypes.SendActivationEmailSuccess:
    case UserActionTypes.SendActivationEmailFailed:
      return {
        ...state,
        userList: state.userList.map(user => {
          if (user.id === (action as SendActivationEmailAction).payload.id)
            return {
              ...user,
              sendingActivationEmail: false
            };

          return user;
        })
      };

    default:
      return state;
  }
}

export const getUserList = (state: State) => state.userList;
export const getAllowAddingEditing = (state: State) => !state.editingUser;
export const getEditingUser = (state: State) => state.editingUser;
