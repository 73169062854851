import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ExistingProvider,
  forwardRef,
  Input,
  Renderer2
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { noop } from 'rxjs';

import { formatNumber } from '../util';

const NUMBER_ACCESSOR: ExistingProvider = {
  provide: NG_VALUE_ACCESSOR,
  // tslint:disable-next-line: no-forward-ref
  useExisting: forwardRef(() => DpcNumberComponent),
  multi: true
};

@Component({
  selector: 'dpc-number-input',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NUMBER_ACCESSOR],
  template: `
    <input type="text"
           class="form-control"
           [ngModel]="stringValue"
           (ngModelChange)="modelChanged($event)"
           (keydown)="keyDown($event)"
           (blur)="blur($event)"/>
  `
})
export class DpcNumberComponent implements ControlValueAccessor {
  @Input() scale = 2;
  @Input() thousandSeparator = ',';
  @Input() decimalSeparator = '.';

  stringValue: string;
  numberValue: number;
  disabled: boolean;

  private onChangeCallback: (_: any) => void = noop;
  private onTouchedCallback: () => void = noop;
  private _prevValue: number;

  constructor(private cdr: ChangeDetectorRef,
              private renderer: Renderer2) {

  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    if (this._prevValue === obj)
      return;

    this._prevValue = obj;

    this.stringValue = obj ? this.formatNumber(obj) : '';
    this.numberValue = typeof obj === 'number' ? obj : Number.parseFloat(obj);

    this.cdr.markForCheck();
  }

  modelChanged($event: string): void {
    const num = this.getParsedValue($event);

    if (!Number.isNaN(num))
      this.onChangeCallback(num);
  }

  // tslint:disable-next-line: cyclomatic-complexity
  keyDown($event: KeyboardEvent): void {
    if (
      [9, 27, 13, 110, 8, 46].indexOf($event.keyCode) !== -1 ||
      // Allow: Ctrl+A
      ($event.keyCode === 65 && ($event.ctrlKey || $event.metaKey)) ||
      // Allow: Ctrl+C
      ($event.keyCode === 67 && ($event.ctrlKey || $event.metaKey)) ||
      // Allow: Ctrl+V
      ($event.keyCode === 86 && ($event.ctrlKey || $event.metaKey)) ||
      // Allow: Ctrl+X
      ($event.keyCode === 88 && ($event.ctrlKey || $event.metaKey)) ||
      // Allow: home, end, left, right
      ($event.keyCode >= 35 && $event.keyCode <= 39))
    // let it happen, don't do anything
      return;

    // Ensure that it is a number and stop the keypress
    if ($event.shiftKey
      || ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '-', this.decimalSeparator, this.thousandSeparator]
        .indexOf($event.key) === -1) {
      $event.preventDefault();

      return;
    }

    const input = ($event.target as HTMLInputElement);
    const cursorStartPosition = input.selectionStart;
    const cursorEndPosition = input.selectionEnd;
    const beforeCusorText = input.value.substr(0, cursorStartPosition);
    const afterCursorText = input.value.substr(cursorEndPosition);
    const newString = beforeCusorText + $event.key + afterCursorText;
    const decimalSplit = newString.split(this.decimalSeparator);

    // already exist decimal part
    if (decimalSplit.length > 2) {
      $event.preventDefault();

      return;
    }

    let decimalPart: string;

    if (decimalSplit.length === 2)
      decimalPart = decimalSplit[1];

    if (decimalPart && decimalPart.indexOf(this.thousandSeparator) > -1) {
      $event.preventDefault();

      return;
    }

    const num = this.getParsedValue(newString);

    if (Number.isNaN(num))
      this.markAsInvalid(input);
    else
      this.markAsValid(input);

  }

  blur($event): void {
    const input = ($event.target as HTMLInputElement);

    const num = this.getParsedValue(input.value);

    if (Number.isNaN(num))
      return;

    input.value = this.formatNumber(num);
  }

  private getParsedValue(value: string): number {
    const replacedText = value.replace(new RegExp(this.thousandSeparator, 'g'), '');

    return Number.parseFloat(replacedText);
  }

  private formatNumber(value: number | string): string {
    if (value === null || value === undefined)
      return '';

    let num;
    if (typeof value === 'string') {
      num = Number.parseFloat(value);

      if (Number.isNaN(num))
        return '';
    } else
      num = value;

    return formatNumber(num, {
      decimalPlaces: this.scale,
      decimalSeparator: this.decimalSeparator,
      thousandSeparator: this.thousandSeparator
    });
  }

  private markAsInvalid(input: HTMLElement): void {
    this.renderer.addClass(input, 'ng-invalid');
    this.renderer.removeClass(input, 'ng-valid');
  }

  private markAsValid(input: HTMLElement): void {
    this.renderer.removeClass(input, 'ng-invalid');
    this.renderer.addClass(input, 'ng-valid');

  }
}
