import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { State } from '../reducers/app.reducer';

@Component({
  selector: 'dpc-header',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="header-container">
      <div class="logo-container"
           [class.opened]="state.sideMenuVisible"
           [class.closed]="!state.sideMenuVisible">
        <img [src]="headerLogoSrc"
             class="img-logo"
             alt="Daikin logo">
      </div>
      <div class="menu-icon-container align-middle">
        <div [class.close-menu-rectangle]="state.sideMenuVisible"
             [class.open-menu-rectangle]="!state.sideMenuVisible"
             (click)="toggleSideMenu.emit()"
             ngbTooltip="Toggle left side menu"
             placement="right"></div>
      </div>
      <div class="pull-right mr-3">
        <div ngbDropdown
             placement="bottom-right">
          <span class="fa fa-user-circle pointer"
                id="header-user-menu"
                ngbDropdownToggle></span>
          <div ngbDropdownMenu
               aria-labelledby="header-user-menu">
            <button class="dropdown-item" (click)="logout.emit()">Logout</button>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @Input() state: State;
  @Input() headerLogoSrc: string;

  @Output() toggleSideMenu = new EventEmitter<void>();
  @Output() logout = new EventEmitter<void>();
}
