import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'dpc-invalid-feedback',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ng-container *ngFor="let error of intErrors">
      <div class="invalid-feedback"
           *ngIf="isErrorVisible(error.key)">
        {{ error.text }}
      </div>
    </ng-container>

  `
})
export class DpcInvalidFeedbackComponent implements OnChanges, OnDestroy {

  @Input() form: FormGroup;
  @Input() controlName: string;
  @Input() errors: {
    [index: string]: string
  };

  intErrors = [];

  private dataChangeSubscription: Subscription;

  constructor(private cdRef: ChangeDetectorRef) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['errors']) {
      const errs = changes['errors'].currentValue;
      const keys = Object.getOwnPropertyNames(errs);
      const errArray = [];

      for (const key of keys)
        errArray.push({
          key,
          text: errs[key]
        });

      this.intErrors = errArray;
    }

    if (changes['form'] || changes['controlName'])
      if (this.form && this.controlName) {
        if (this.dataChangeSubscription)
          this.dataChangeSubscription.unsubscribe();

        this.dataChangeSubscription = this.form.controls[this.controlName].statusChanges.subscribe(() => {
          this.cdRef.markForCheck();
        });
      }
  }

  ngOnDestroy(): void {
    if (this.dataChangeSubscription)
      this.dataChangeSubscription.unsubscribe();
  }

  isErrorVisible(error): boolean {
    const control: AbstractControl = this.form.controls[this.controlName];

    if (!control || !control.errors)
      return false;

    if (!control.dirty && !control.touched)
      return false;

    return !!control.errors[error];
  }
}
