import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dpcDash'
})
export class DashPipe implements PipeTransform {
  transform(value: any): string {
    if (value === null || value === undefined)
      return '-';

    if (typeof value === 'string' && value.trim().length === 0)
      return '-';

    return value;
  }
}
