import { Action } from '@ngrx/store';

import { ProductsCountOfBrand } from '../models/products-count-of-brand';
import { PriceCollectorAuditLogResponse } from '../models/price-collector-audit-log';
import { PriceCollectorAuditLogForm } from '../models/price-collector-audit-log-form';
import { PriceStatistic } from '../models/price-statistic';

export enum ReportActionTypes {
  LoadProductStatistic = '[Report] Load product statistic',
  LoadProductStatisticSuccess = '[Report] Load product statistic success',
  LoadProductStatisticFailed = '[Report] Load product statistic failed',
  InitPriceCollectorAuditLog = '[Report] Init price collector audit log',
  PriceCollectorAuditLogFromChange = '[Report] Price collector audit log form change',
  LoadPriceCollectorAuditLogSuccess = '[Report] Load price collector audit log success',
  LoadPriceCollectorAuditLogFailed = '[Report] Load price collector audit log failed',
  LoadPriceCollectorPageChange = '[Report] Load price collector page change',
  LoadPriceStatistic = '[Report] Load price statistic',
  LoadPriceStatisticSuccess = '[Report] Load price statistic success',
  LoadPriceStatisticFailed = '[Report] Load price statistic failed'
}

export class LoadProductStatisticAction implements Action {
  readonly type = ReportActionTypes.LoadProductStatistic;
}

export class LoadProductStatisticSuccessAction implements Action {
  readonly type = ReportActionTypes.LoadProductStatisticSuccess;

  constructor(public payload: Array<ProductsCountOfBrand>) {
  }
}

export class LoadProductStatisticFailedAction implements Action {
  readonly type = ReportActionTypes.LoadProductStatisticFailed;

  constructor(public payload: any) {
  }
}

export class InitPriceCollectorAuditLogAction implements Action {
  readonly type = ReportActionTypes.InitPriceCollectorAuditLog;
}

export class LoadPriceCollectorAuditLogAction implements Action {
  readonly type = ReportActionTypes.PriceCollectorAuditLogFromChange;

  constructor(public payload: PriceCollectorAuditLogForm) {
  }
}

export class LoadPriceCollectorAuditLogSuccessAction implements Action {
  readonly type = ReportActionTypes.LoadPriceCollectorAuditLogSuccess;

  constructor(public payload: PriceCollectorAuditLogResponse) {
  }
}

export class LoadPriceCollectorAuditLogFailedAction implements Action {
  readonly type = ReportActionTypes.LoadPriceCollectorAuditLogFailed;

  constructor(public payload: any) {
  }
}

export class LoadPriceCollectorAuditLogPageChangeAction implements Action {
  readonly type = ReportActionTypes.LoadPriceCollectorPageChange;

  constructor(public payload: number) {
  }
}

export class LoadPriceStatisticAction {
  readonly type = ReportActionTypes.LoadPriceStatistic;
}

export class LoadPriceStatisticActionSuccess {
  readonly type = ReportActionTypes.LoadPriceStatisticSuccess;

  constructor(public payload: PriceStatistic) {
  }
}

export class LoadPriceStatisticActionFailed {
  readonly type = ReportActionTypes.LoadPriceStatisticFailed;

  constructor(public payload: any) {
  }
}

export type ReportActions =
  LoadProductStatisticAction
  | LoadProductStatisticSuccessAction
  | LoadProductStatisticFailedAction
  | InitPriceCollectorAuditLogAction
  | LoadPriceCollectorAuditLogAction
  | LoadPriceCollectorAuditLogSuccessAction
  | LoadPriceCollectorAuditLogFailedAction
  | LoadPriceCollectorAuditLogPageChangeAction
  | LoadPriceStatisticAction
  | LoadPriceStatisticActionSuccess
  | LoadPriceStatisticActionFailed
  ;
