import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ChartData, ChartOptions } from 'chart.js';

import * as fromProdStat from '../reducers/product-statistic.reducer';
import { dynamicColor } from '../utils';

@Component({
  selector: 'dpc-product-statistic',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <h3>Brands and Products in the database</h3>

    <div>
      <div>
        Total nr. of brands: {{ state.products.length }}
      </div>
      <div>
        Total nr. of products: {{ nrOfProducts }}
      </div>
    </div>

    <div class="chart-container">
      <p-chart type="doughnut"
               [data]="data"
               [options]="options"></p-chart>
    </div>
  `
})
export class ProductStatisticComponent implements OnChanges {
  @Input() state: fromProdStat.State;

  data: ChartData;
  options: ChartOptions = {
    tooltips: {
      callbacks: {
        label: (tooltipItem, data) => {
          const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] as number;
          const label = data.labels[tooltipItem.index];
          const percent = Math.round(value / this.nrOfProducts * 100);

          return `${label}: ${value} (${percent}%)`;
        }
      }
    }

  };
  nrOfProducts = 0;

  ngOnChanges(changes: SimpleChanges): void {
    const data: Array<number> = [];
    const backgroundColor: Array<string> = [];
    this.data = { datasets: [{ data, backgroundColor }], labels: [] };
    this.nrOfProducts = 0;

    if (this.state.products)
      for (const brand of this.state.products) {
        data.push(brand.productCount);
        backgroundColor.push(dynamicColor());
        this.data.labels.push(brand.brand);

        this.nrOfProducts += brand.productCount || 0;
      }

  }
}
