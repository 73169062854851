import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';
import { environment } from '../../../environments/environment';

@Directive({
  selector: '[dpcDynamicImage]'
})
export class DynamicImageDirective implements OnChanges {
  // tslint:disable-next-line: no-input-rename
  @Input('dpcDynamicImage') imageSrc: string;

  constructor(private el: ElementRef,
              private renderer2: Renderer2) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['imageSrc'])
      this.setImageSrc();
  }

  private setImageSrc(): void {
    if (this.imageSrc) {
      const url = `${environment.backendUrl}/nonsecure/v1/image/${this.imageSrc}`;
      this.renderer2.setAttribute(this.el.nativeElement, 'src', url);
    } else
      this.renderer2.removeAttribute(this.el.nativeElement, 'src');
  }
}
