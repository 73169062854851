import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromRoot from '../../reducers';
import * as fromAuth from './auth.reducer';
import * as fromActivation from './activate-user.reducer';
import * as fromLoginPage from './login-page.reducer';
import * as fromForgotPasswordPage from './forgot-password.reducer';
import * as fromChangePasswordPage from './change-password.reducer';

export const FEATURE_NAME = 'auth';

export interface AuthState {
  status: fromAuth.State;
  loginPage: fromLoginPage.State;
  activateUserPage: fromActivation.State;
  forgotPasswordPage: fromForgotPasswordPage.State;
  changePasswordPage: fromChangePasswordPage.State;
}

export interface State extends fromRoot.State {
  auth: AuthState;
}

export const reducers = {
  status: fromAuth.reducer,
  loginPage: fromLoginPage.reducer,
  activateUserPage: fromActivation.reducer,
  forgotPasswordPage: fromForgotPasswordPage.reducer,
  changePasswordPage: fromChangePasswordPage.reducer
};

export const selectAuthState = createFeatureSelector<AuthState>(FEATURE_NAME);

export const selectAuthStatusState = createSelector(
  selectAuthState,
  (state: AuthState) => state.status
);
export const getLoggedIn = createSelector(
  selectAuthStatusState,
  fromAuth.getLoggedIn
);

export const userRoleSelector = (selector: boolean, hasRole: boolean) => selector && hasRole;

export const getUser = createSelector(selectAuthStatusState, fromAuth.getUser);
export const isAdminUser = createSelector(selectAuthStatusState, fromAuth.isAdminUser);
export const isEditorUser = createSelector(selectAuthStatusState, fromAuth.isEditorUser);

export const selectLoginPageState = createSelector(
  selectAuthState,
  (state: AuthState) => state.loginPage
);
export const getLoginPageError = createSelector(
  selectLoginPageState,
  fromLoginPage.getError
);
export const getLoginPagePending = createSelector(
  selectLoginPageState,
  fromLoginPage.getPending
);

export const selectActivateUserState = createSelector(
  selectAuthState,
  (state: AuthState) => state.activateUserPage
);

export const getActivateUserPage = createSelector(selectActivateUserState, fromActivation.getState);

export const selectForgotPasswordState = createSelector(
  selectAuthState,
  (state: AuthState) => state.forgotPasswordPage
);

export const getForgotPasswordPage = createSelector(selectForgotPasswordState, fromForgotPasswordPage.getState);

export const selectChangePasswordState = createSelector(
  selectAuthState,
  (state: AuthState) => state.changePasswordPage
);

export const getChangePasswordPage = createSelector(selectChangePasswordState, fromChangePasswordPage.getState);
