import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { LOAD_CURRENCIES, LoadCurrenciesSuccessAction } from '../actions/currency.actions';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { CurrencyService } from '../services/currency.service';
import { SaveOrgFailedAction } from '../actions/org.actions';
import { httpErrorHandler } from '../../shared/http-error-handler';

@Injectable()
export class CurrenciesEffects {

  @Effect()
  loadCurrencies$ = this.actions$.pipe(
    ofType(LOAD_CURRENCIES),
    switchMap(() => this.currencyService.list()
      .pipe(
        map(currencies => new LoadCurrenciesSuccessAction(currencies)),
        catchError(err => {
            const error = httpErrorHandler(err);

            return of(new SaveOrgFailedAction(error.message));
          }
        )
      )
    )
  );

  constructor(private actions$: Actions,
              private currencyService: CurrencyService) {

  }

}
