import { ProductsCountOfBrand } from '../models/products-count-of-brand';
import { ReportActions, ReportActionTypes } from '../actions/report.actions';

export interface State {
  products: Array<ProductsCountOfBrand>;
  loading: boolean;
  error?: string;
}

export const initialState: State = {
  products: [],
  loading: false
};

export function reducer(state: State = initialState, action: ReportActions): State {
  switch (action.type) {

    case ReportActionTypes.LoadProductStatistic:
      return {
        ...state,
        loading: true,
        products: [],
        error: null
      };

    case ReportActionTypes.LoadProductStatisticSuccess:
      return {
        ...state,
        loading: false,
        products: [...action.payload]
          .sort((a, b) => {
            return a.brand.localeCompare(b.brand);
          })
      };

    case ReportActionTypes.LoadProductStatisticFailed:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    default:
      return state;
  }
}
