import * as auth from '../actions/activate-user.actions';
import { LOGIN_SUCCESS, LoginSuccess } from '../actions/auth.actions';

export interface State {
  token?: string;
  error: string | null;
  pending: boolean;
}

export const initialState: State = {
  error: null,
  pending: false
};

export function reducer(state = initialState, action: auth.Actions | LoginSuccess): State {
  switch (action.type) {
    case auth.ROUTE_TOKEN_CHANGED: {
      return {
        ...state,
        token: action.payload,
        error: action.payload ? null : 'Invalid activation token',
        pending: false
      };
    }

    case auth.ACTIVATE_USER: {
      return {
        ...state,
        error: null,
        pending: true
      };
    }

    case auth.ACTIVATE_USER_FAILED: {
      return {
        ...state,
        error: action.payload,
        pending: false
      };
    }

    case LOGIN_SUCCESS: {
      return {
        ...state,
        error: null,
        token: null,
        pending: false
      };
    }

    default: {
      return state;
    }
  }
}

export const getState = (state: State) => state;
