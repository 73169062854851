import { Action } from '@ngrx/store';

export enum AppActionTypes {
  ToggleSideMenu = '[App] Toggle side menu',
  ToggleAdminMenu = '[App] Toggle admin menu',
  AddToUrlQueryParams = '[App] Add to url query params'
}

export class ToggleSideMenuAction implements Action {
  type = AppActionTypes.ToggleSideMenu;
}

export class ToggleAdminMenuAction implements Action {
  type = AppActionTypes.ToggleAdminMenu;
}

export class AddToUrlQueryParamsAction implements Action {
  type = AppActionTypes.AddToUrlQueryParams;

  constructor(public payload: any) {
  }
}

export type AppActions =
  ToggleSideMenuAction
  | ToggleAdminMenuAction
  | AddToUrlQueryParamsAction
  ;
