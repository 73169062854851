import { Action } from '@ngrx/store';
import { ChangePasswordData } from '../models/change-password-data';

export const INIT_CHANGE_PASSWORD_PAGE = '[Auth] init change password page';
export const CHANGE_PASSWORD = '[Auth] Change password';
export const CHANGE_PASSWORD_SUCCESS = '[Auth] Change password success';
export const CHANGE_PASSWORD_FAILED = '[Auth] Change password failed';

export class InitChangePasswordPageAction implements Action {
  type = INIT_CHANGE_PASSWORD_PAGE;
}

export class ChangePasswordAction implements Action {
  type = CHANGE_PASSWORD;

  constructor(public payload: ChangePasswordData) {
  }
}

export class ChangePasswordSuccessAction implements Action {
  type = CHANGE_PASSWORD_SUCCESS;
}

export class ChangePasswordFailedAction implements Action {
  type = CHANGE_PASSWORD_FAILED;

  constructor(public payload: any) {
  }
}

export type Actions =
  InitChangePasswordPageAction
  | ChangePasswordAction
  | ChangePasswordSuccessAction
  | ChangePasswordFailedAction
  ;
