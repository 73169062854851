import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import { IMultiSelectOption } from 'angular-2-dropdown-multiselect';

import * as fromRoot from '../../reducers';
import * as fromOrgList from './list-org.reducer';
import * as fromUserList from './list-user.reducer';
import * as fromCurrencies from './currency.reducer';
import { Organization } from '../models/organization';
import { getUser, isAdminUser } from '../../auth/reducers';

export const FEATURE_NAME = 'orgData';

export interface OrganizationState {
  orgList: fromOrgList.State;
  userList: fromUserList.State;
  currencies: fromCurrencies.State;
}

export interface State extends fromRoot.State {
  orgData: OrganizationState;
}

export const reducers: ActionReducerMap<OrganizationState> = {
  orgList: fromOrgList.reducer,
  userList: fromUserList.reducer,
  currencies: fromCurrencies.reducer
};

export const getOrgState = createFeatureSelector<OrganizationState>(FEATURE_NAME);

export const getOrgListState = createSelector(getOrgState, state => state.orgList);
export const getUserListState = createSelector(getOrgState, state => state.userList);
export const getCurrenciesState = createSelector(getOrgState, state => state.currencies);

export const getOrgList = createSelector(getOrgListState, fromOrgList.getOrgList);
export const getEditingOrg = createSelector(getOrgListState, fromOrgList.getEditingOrg);
export const getOrgMultiSelectList = createSelector(getOrgListState, fromOrgList.getOrgMultiSelectList);

export const getUserList = createSelector(getUserListState, fromUserList.getUserList);
export const getEditing = createSelector(getUserListState, fromUserList.getEditingUser);
export const getAllowAddingEditing = createSelector(getUserListState, fromUserList.getAllowAddingEditing);
export const allowAddingOrEditingUser = createSelector(
  getAllowAddingEditing,
  isAdminUser,
  (selector: boolean, isAdmin: boolean) => isAdmin && selector);

export const getParentOrgList = createSelector(getOrgList, getEditingOrg,
  (orgList: Array<Organization>, newOrg: Organization): Array<Organization> => {
    if (!newOrg || !newOrg.id)
      return orgList;

    return orgList.filter(x => x.id !== newOrg.id);
  });

export const getCurrencyList = createSelector(getCurrenciesState, fromCurrencies.getCurrencyList);

export const hasRightToAddEditOrg = createSelector(getOrgState, isAdminUser, (state, isAdmin) => isAdmin);

export const getUserOrgList = createSelector(getOrgList, getUser,
  (orgs, user) => orgs.filter(org => user.orgs.indexOf(org.id) > -1)
);

export const getUserOrg = createSelector(getOrgList, getUser,
  (orgs, user) => orgs.find(org => user.orgId === org.id) || ({ id: user.orgId } as Organization)
);

export const getUserCurrencies = createSelector(
  isAdminUser,
  getUserOrg,
  getCurrencyList,
  (isAdmin: boolean, org: Organization, allCurrencies: Array<IMultiSelectOption>): Array<IMultiSelectOption> => {
    if (isAdmin)
      return allCurrencies;

    const currencies = [
      {
        id: 'EUR',
        name: 'EUR'
      }
    ];

    if (org.currency && org.currency !== 'EUR')
      currencies.push({
        id: org.currency,
        name: org.currency
      });

    return currencies.sort(fromCurrencies.sortCurrencies);
  }
);
