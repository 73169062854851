import * as moment from 'moment';

import { PriceCollectorAuditLog } from '../models/price-collector-audit-log';
import { PriceCollectorAuditLogFilter } from '../models/price-collector-audit-log-filter';
import { ReportActions, ReportActionTypes } from '../actions/report.actions';

export interface State {
  startDate: Date;
  endDate: Date;
  count: number;
  rows: Array<PriceCollectorAuditLog>;
  currentPage: number;
  recordPerPage: number;
  loading: boolean;
  error?: string;
}

export const initialState: State = {
  startDate: moment().add(-30, 'day').startOf('day').toDate(),
  endDate: moment().endOf('day').toDate(),
  count: 0,
  rows: [],
  currentPage: 1,
  recordPerPage: 25,
  loading: false
};

export function reducer(state: State = initialState, action: ReportActions): State {
  switch (action.type) {

    case ReportActionTypes.PriceCollectorAuditLogFromChange:
      return {
        ...state,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
        rows: [],
        loading: true,
        error: null
      };

    case ReportActionTypes.LoadPriceCollectorAuditLogSuccess:
      return {
        ...state,
        loading: false,
        count: action.payload.count,
        rows: action.payload.rows
      };

    case ReportActionTypes.LoadPriceCollectorAuditLogFailed:
      return {
        ...state,
        loading: false,
        error: action.payload
      };

    case ReportActionTypes.LoadPriceCollectorPageChange:
      return {
        ...state,
        currentPage: action.payload
      };

    default:
      return state;
  }
}

export const getPriceCollectorFilter = (state: State): PriceCollectorAuditLogFilter => ({
  startDate: state.startDate,
  endDate: state.endDate,
  count: state.recordPerPage,
  offset: (state.recordPerPage - 1) * state.currentPage
});
