import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'dpc-add-other-value',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="modal-header">
      <h4 class="modal-title">{{ title }}</h4>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form [formGroup]="form">
        <div class="form-group">
          <input type="text"
                 class="form-control"
                 placeholder="Value"
                 required
                 formControlName="otherValue"
                 [maxlength]="maxLength"/>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button"
              class="btn btn-primary"
              [disabled]="form.invalid"
              (click)="add()">Add
      </button>
    </div>
  `
})
export class AddOtherValueComponent {
  @Input() title = 'Add new item';
  @Input() maxLength: number;

  form = new FormGroup({
    otherValue: new FormControl('', [Validators.required])
  });

  constructor(public activeModal: NgbActiveModal) {
  }

  add(): void {
    if (this.form.invalid)
      return;

    this.activeModal.close({ command: 'add', value: this.form.value.otherValue });
  }
}
