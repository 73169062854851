import { Action } from '@ngrx/store';

import { Notification } from '../models/notification';

export const APP_SHOW_NOTIFICATION = '[App] show notification';

export class ShowNotificationAction implements Action {
  type = APP_SHOW_NOTIFICATION;

  constructor(public payload: Notification) { }
}
