import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { OrgService } from '../services/org.service';
import {
  DeleteUserAction,
  DeleteUserFailedAction,
  DeleteUserSuccessAction,
  LoadUsersAction,
  LoadUsersFailedAction,
  LoadUsersSuccessAction,
  SaveUserAction,
  SaveUserFailedAction,
  SaveUserSuccessAction, SendActivationEmailAction, SendActivationEmailFailedAction, SendActivationEmailSuccessAction,
  UserActionTypes
} from '../actions/user.actions';
import { httpErrorHandler } from '../../shared/http-error-handler';
import { ShowNotificationAction } from '../../actions/app.actions';
import { NotificationType } from 'src/app/models';

@Injectable()
export class UserEffects {

  @Effect()
  loadUsers$ = this.actions$.pipe(
    ofType<LoadUsersAction>(UserActionTypes.LoadUsers),
    switchMap(() => this.orgService.listUser()
      .pipe(
        map(users => new LoadUsersSuccessAction(users)),
        catchError(err => {
          const error = httpErrorHandler(err);

          return of(new LoadUsersFailedAction(error));
        })
      )
    )
  );

  @Effect()
  saveUser$ = this.actions$.pipe(
    ofType<SaveUserAction>(UserActionTypes.SaveUser),
    map(action => action.payload),
    switchMap(user => this.orgService.addUser(user)
      .pipe(
        map(savedUser => new SaveUserSuccessAction(savedUser)),
        catchError(err => {
          const error = httpErrorHandler(err);

          return of(new SaveUserFailedAction(error));
        })
      )
    )
  );

  @Effect()
  deleteUser$ = this.actions$.pipe(
    ofType<DeleteUserAction>(UserActionTypes.DeleteUser),
    map(action => action.payload),
    switchMap(user => this.orgService.deleteUser(user)
      .pipe(
        map(() => new DeleteUserSuccessAction(user)),
        catchError(err => {
          const error = httpErrorHandler(err);

          return of(new DeleteUserFailedAction(error));
        })
      )
    )
  );

  @Effect() resendActivationEmail$ = this.actions$
    .pipe(
      ofType<SendActivationEmailAction>(UserActionTypes.SendActivationEmail),
      map(action => action.payload),
      switchMap(user => this.orgService.sendUserActivationEmail(user)
        .pipe(
          map(() => new SendActivationEmailSuccessAction(user)),
          catchError(err => {
            const error = httpErrorHandler(err);

            return [
              new ShowNotificationAction({
                type: NotificationType.Error,
                message: error.message,
                title: 'Resend Activation email error'
              }),
              new SendActivationEmailFailedAction(user)
            ];
          })
        )
      )
    );

  constructor(private actions$: Actions,
              private orgService: OrgService) {
  }
}
