import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'dpc-not-authorized',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="row">
       <div class="col-md-12">
            <h1 class="text-center">
                Access denied
            </h1>
            <p>You are not authorized to access the content.</p>
        </div>
    </div>
  `
})
export class NotAuthorizedPageComponent {
}
