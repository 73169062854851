import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { defer, EMPTY, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../../../environments/environment';

import { User } from '../models/user';
import { Login, LOGIN, LOGIN_SUCCESS, LoginFailure, LoginSuccess, Logout, LOGOUT } from '../actions/auth.actions';
import { Authenticate } from '../models/authenticate';
import { AuthService } from '../services/auth.service';
import { httpErrorHandler } from '../../shared/http-error-handler';
import { ActivateUser } from '../models/activate-user';
import { ACTIVATE_USER, ActivateUserAction, ActivateUserFailedAction } from '../actions/activate-user.actions';
import { WindowRef } from '../../shared/window-ref';
import {
  FORGOT_PASSWORD,
  ForgotPasswordAction,
  ForgotPasswordFailedAction,
  ForgotPasswordSuccessAction
} from '../actions/forgot-password.actions';
import {
  CHANGE_PASSWORD,
  ChangePasswordAction,
  ChangePasswordFailedAction,
  ChangePasswordSuccessAction
} from '../actions/change-password.actions';
import { ChangePasswordData } from '../models/change-password-data';
import { ListOrgAction } from '../../org/actions/org.actions';

@Injectable()
export class AuthEffects {

  // defer effect should be the first
  @Effect()
  loadJwTTokenFromLocalStorage$ = defer(() => {
    try {
      const token = localStorage.getItem(environment.JwtTokenId);
      if (this.jwtHelper.isTokenExpired(token))
        localStorage.removeItem(environment.JwtTokenId);
      else {
        const user: User = this.jwtHelper.decodeToken(token);

        return of(new LoginSuccess(user));
      }
    } catch (error) {
      localStorage.removeItem(environment.JwtTokenId);
    }

    return EMPTY;
  });

  @Effect()
  login$ = this.actions$.pipe(
    ofType<Login>(LOGIN),
    map(action => action.payload),
    switchMap((authData: Authenticate) => {
      return this.authService.login(authData)
        .pipe(
          map(response => response.token),
          map((jwtToken: string) => {
            const result = this.storeJwtToken(jwtToken);

            if (result.success)
              return new LoginSuccess(result.user);

            return new LoginFailure(result.error);
          }),
          catchError(err => {
            const error = httpErrorHandler(err);

            return of(new LoginFailure(error.message));
          })
        );
    })
  );

  @Effect()
  loginSuccess$ = this.actions$.pipe(
    ofType(LOGIN_SUCCESS),
    tap(() => {
      return this.router.navigate(['/']);
    }),
    map(() => new ListOrgAction())
  );

  @Effect()
  activateUser$ = this.actions$.pipe(
    ofType<ActivateUserAction>(ACTIVATE_USER),
    map(action => action.payload),
    switchMap((data: ActivateUser) => {
      return this.authService.activateUser(data)
        .pipe(
          map(response => response.token),
          map((jwtToken: string) => {
            const result = this.storeJwtToken(jwtToken);

            if (result.success)
              return new LoginSuccess(result.user);

            return new ActivateUserFailedAction(result.error);
          }),
          catchError(err => {
            const error = httpErrorHandler(err);

            return of(new ActivateUserFailedAction(error.message));
          })
        );
    })
  );

  @Effect()
  forgotPassword$ = this.actions$.pipe(
    ofType<ForgotPasswordAction>(FORGOT_PASSWORD),
    map(action => action.payload),
    switchMap((email: string) => {
      return this.authService.forgotPassword(email)
        .pipe(
          map(() => new ForgotPasswordSuccessAction()),
          catchError(err => {
            const error = httpErrorHandler(err);

            return of(new ForgotPasswordFailedAction(error.message));
          })
        );
    })
  );

  @Effect()
  changePassword$ = this.actions$.pipe(
    ofType<ChangePasswordAction>(CHANGE_PASSWORD),
    map(action => action.payload),
    switchMap((data: ChangePasswordData) => {
      return this.authService.changePassword(data)
        .pipe(
          map(() => new ChangePasswordSuccessAction()),
          catchError(err => {
            const error = httpErrorHandler(err);

            return of(new ChangePasswordFailedAction(error.message));
          })
        );
    })
  );

  @Effect({ dispatch: false })
  logout$ = this.actions$.pipe(
    ofType<Logout>(LOGOUT),
    tap(() => {
      localStorage.removeItem(environment.JwtTokenId);
      this.router.navigate(['/auth/login']);
    })
  );

  constructor(private actions$: Actions,
              private router: Router,
              private authService: AuthService,
              private window: WindowRef,
              private jwtHelper: JwtHelperService) {
  }

  private storeJwtToken(jwtToken: string): { success: boolean, user?: User, error?: any } {

    try {
      const user: User = this.jwtHelper.decodeToken(jwtToken);
      this.window.nativeWindow.localStorage.setItem(environment.JwtTokenId, jwtToken);

      return {
        success: true,
        user
      };
    } catch (error) {
      this.window.nativeWindow.localStorage.removeItem(environment.JwtTokenId);

      return {
        success: false,
        error
      };
    }
  }
}
