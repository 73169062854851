import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';

import { State as PageState } from '../reducers/forgot-password.reducer';

@Component({
  selector: 'dpc-forgot-password-form',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="row justify-content-md-center">
      <div class="col-md-4">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <div class="input-group">
              <div class="input-group-prepend"><span class="input-group-text daikin-mail-icon"></span></div>
              <input type="text"
                     class="form-control"
                     id="email"
                     placeholder="E-mail"
                     formControlName="email">
            </div>
            <ng-container *ngIf="form.controls['email'].dirty || form.controls['email'].touched">
              <div class="invalid-feedback"
                   *ngIf="form.controls['email'].errors?.required">
                E-mail is required
              </div>
              <div class="invalid-feedback"
                   *ngIf="form.controls['email'].errors?.email">
                Wrong e-mail address format
              </div>
            </ng-container>
          </div>

          <div class="alert alert-danger" role="alert" *ngIf="state.error">
            {{ state.error }}
          </div>

          <div class="alert alert-success" role="alert" *ngIf="state.success">
            Password reset email sent to the given e-mail address.
          </div>

          <a [routerLink]="['/auth/login']"
             class="btn btn-link">Back to Login</a>

          <button type="submit" class="btn btn-primary half-fluid-button float-right"
                  [disabled]="!form.valid || state.pending">Submit
            <div class="animation-container" *ngIf="state.pending">
              <div class="dot"></div>
              <div class="dot"></div>
              <div class="dot"></div>
            </div>
          </button>
        </form>
      </div>
    </div>
  `
})
export class ForgotPasswordComponent {

  @Input() state: PageState;

  @Output() submitted = new EventEmitter<string>();

  form: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, CustomValidators.email])
  });

  onSubmit(): void {
    if (this.form.valid)
      this.submitted.emit(this.form.value.email);
  }
}
