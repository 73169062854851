import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

@Injectable()
export class CurrencyService {
  constructor(private http: HttpClient) {
  }

  list(): Observable<Array<string>> {
    const url = `${environment.backendUrl}/api/v1/currencies`;

    return this.http.get<Array<string>>(url);
  }

}
