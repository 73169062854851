import { IMultiSelectOption } from 'angular-2-dropdown-multiselect';

import {
  Actions,
  LOAD_CURRENCIES,
  LOAD_CURRENCIES_FAILED,
  LOAD_CURRENCIES_SUCCESS, LoadCurrenciesFailedAction,
  LoadCurrenciesSuccessAction
} from '../actions/currency.actions';
import { SAVE_ORG_SUCCESS, SaveOrgSuccessAction } from '../actions/org.actions';

export interface State {
  currencies: Array<string>;
  loading: boolean;
  error?: string;
}

export const initialState: State = {
  currencies: [],
  loading: false,
  error: null
};

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {

    case LOAD_CURRENCIES:
      return {
        currencies: [],
        loading: true,
        error: null
      };

    case LOAD_CURRENCIES_SUCCESS:
      return {
        currencies: (action as LoadCurrenciesSuccessAction).payload,
        loading: false
      };

    case  LOAD_CURRENCIES_FAILED:
      return {
        currencies: [],
        loading: false,
        error: (action as LoadCurrenciesFailedAction).payload
      };

    case SAVE_ORG_SUCCESS: {
      const org = (action as SaveOrgSuccessAction).payload;
      const currency = state.currencies.some(x => x === org.currency);

      if (currency)
        return state;

      return {
        ...state,
        currencies: [...state.currencies, org.currency]
      };
    }

    default:
      return state;
  }
}

export const getCurrencyList = (state: State): Array<IMultiSelectOption> => state.currencies
  .map(x => {
    return {
      id: x,
      name: x
    };
  }).sort(sortCurrencies)
;

export const sortCurrencies = (a: IMultiSelectOption, b: IMultiSelectOption) => a.id.localeCompare(b.id);
