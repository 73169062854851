import * as auth from '../actions/forgot-password.actions';

export interface State {
  error: string | null;
  pending: boolean;
  success?: boolean;
}

export const initialState: State = {
  error: null,
  pending: false
};

export function reducer(state = initialState, action: auth.Actions): State {
  switch (action.type) {
    case auth.FORGOT_PASSWORD: {
      return {
        ...state,
        error: null,
        pending: true
      };
    }

    case auth.FORGOT_PASSWORD_SUCCESS: {
      return {
        ...state,
        error: null,
        pending: false,
        success: true
      };
    }

    case auth.FORGOT_PASSWORD_FAILED: {
      return {
        ...state,
        error: action.payload,
        pending: false
      };
    }

    default: {
      return state;
    }
  }
}

export const getState = (state: State) => state;
