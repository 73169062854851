import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Authenticate } from '../models/authenticate';
import { environment } from '../../../environments/environment';
import { ActivateUser } from '../models/activate-user';
import { ChangePasswordData } from '../models/change-password-data';

@Injectable()
export class AuthService {
  constructor(private http: HttpClient) {
  }

  login(data: Authenticate): Observable<{ token: string }> {
    const url = `${environment.backendUrl}/authentication/v1/login-user`;

    return this.http.post<{ token: string }>(url, data);
  }

  activateUser(data: ActivateUser): Observable<{ token: string }> {
    const url = `${environment.backendUrl}/authentication/v1/activate-user`;

    return this.http.post<{ token: string }>(url, data);
  }

  forgotPassword(email: string): Observable<void> {
    const url = `${environment.backendUrl}/authentication/v1/forgot-password`;

    return this.http.post<void>(url, { email });
  }

  changePassword(data: ChangePasswordData): Observable<void> {
    const url = `${environment.backendUrl}/user-api/v1/change-password`;

    return this.http.post<void>(url, data);
  }
}
