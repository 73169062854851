import { Action } from '@ngrx/store';
import { ActivateUser } from '../models/activate-user';

export const ROUTE_TOKEN_CHANGED = '[Auth] Route token changed';
export const ACTIVATE_USER = '[Auth] Activate user';
export const ACTIVATE_USER_FAILED = '[Auth] Activate user failed';

export class RouteTokenChangedAction implements Action {
  readonly type = ROUTE_TOKEN_CHANGED;

  constructor(public payload: string) {
  }
}

export class ActivateUserAction implements Action {
  readonly type = ACTIVATE_USER;

  constructor(public payload: ActivateUser) {
  }
}

export class ActivateUserFailedAction implements Action {
  readonly type = ACTIVATE_USER_FAILED;

  constructor(public payload: any) {
  }
}

export type Actions =
  RouteTokenChangedAction
  | ActivateUserAction
  | ActivateUserFailedAction
  ;
