import { ROUTER_NAVIGATION, RouterNavigationAction } from '@ngrx/router-store';

import { AppActions, AppActionTypes } from '../actions/app.actions';

export interface State {
  sideMenuVisible: boolean;
  adminMenuExpanded: boolean;
  isIE11: boolean;
}

export const initialState: State = {
  sideMenuVisible: true,
  adminMenuExpanded: false,
  isIE11: !!(window as any).MSInputMethodContext && !!(document as any).documentMode
};

export function reducer(state = initialState, action: AppActions | RouterNavigationAction): State {
  switch (action.type) {

    case AppActionTypes.ToggleSideMenu:
      return {
        ...state,
        sideMenuVisible: !state.sideMenuVisible
      };

    case AppActionTypes.ToggleAdminMenu:
      return {
        ...state,
        adminMenuExpanded: !state.adminMenuExpanded
      };

    case ROUTER_NAVIGATION:
      return {
        ...state,
        sideMenuVisible: (action as RouterNavigationAction).payload.routerState.url.startsWith('/dashboard')
      };

    default:
      return state;
  }
}

export const getHeaderLogoSrc = (state: State): string => state.sideMenuVisible ? 'assets/img/logo.jpg' : 'assets/img/small-logo.jpg';
export const isIE11 = (state: State): boolean => state.isIE11;
