import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';

import { environment } from '../../../environments/environment';
import { State as PageState } from '../reducers/change-password.reducer';
import { ChangePasswordData } from '../models/change-password-data';

@Component({
  selector: 'dpc-change-password-form',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="row justify-content-md-center">
      <div class="col-md-4">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">

          <div class="form-group">
            <div class="input-group">
              <div class="input-group-prepend"><span class="input-group-text daikin-lock-icon"></span></div>
              <input type="password"
                     class="form-control"
                     id="password"
                     placeholder="Current password"
                     formControlName="password">
            </div>

            <ng-container *ngIf="form.controls['password'].dirty || form.controls['password'].touched">
              <div class="invalid-feedback"
                   *ngIf="form.controls['password'].errors?.required">
                Password is required
              </div>
              <div class="invalid-feedback"
                   *ngIf="form.controls['password'].errors?.pattern">
                The password length should be in 8 and 30 character.
                The password should contains minimum one lowercase, uppercase, numeric and special character: '_!?+@:;<>"
              </div>
            </ng-container>

          </div>
          <div class="form-group">
            <div class="input-group">
              <div class="input-group-prepend"><span class="input-group-text daikin-lock-icon"></span></div>
              <input type="password"
                     class="form-control"
                     id="newPassword"
                     placeholder="New password"
                     formControlName="newPassword">
            </div>

            <ng-container *ngIf="form.controls['newPassword'].dirty || form.controls['newPassword'].touched">
              <div class="invalid-feedback"
                   *ngIf="form.controls['newPassword'].errors?.required">
                New password is required
              </div>
              <div class="invalid-feedback"
                   *ngIf="form.controls['newPassword'].errors?.pattern">
                The new password length should be in 8 and 30 character.
                The new password should contains minimum one lowercase, uppercase, numeric and special character: '_!?+@:;<>"
              </div>
            </ng-container>

          </div>

          <div class="form-group">
            <div class="input-group">
              <div class="input-group-prepend"><span class="input-group-text daikin-lock-icon"></span></div>
              <input type="password"
                     class="form-control"
                     id="password-confirm"
                     placeholder="Password confirm"
                     formControlName="passwordConfirm">
            </div>
            <ng-container *ngIf="form.controls['passwordConfirm'].dirty || form.controls['passwordConfirm'].touched">

              <div class="invalid-feedback"
                   *ngIf="form.controls['passwordConfirm'].errors?.required">
                Password confirmation is required
              </div>
              <div class="invalid-feedback"
                   *ngIf="form.controls['passwordConfirm'].errors?.equalTo">
                Password not match with confirmation password
              </div>
            </ng-container>
          </div>


          <div class="alert alert-danger" role="alert" *ngIf="state.error">
            {{state.error}}
          </div>

          <div class="alert alert-success" role="alert" *ngIf="state.message">
            {{state.message}}
          </div>

          <button type="submit" class="btn btn-primary btn-block"
                  [disabled]="!form.valid || state.pending">Change password
            <div class="animation-container" *ngIf="state.pending">
              <div class="dot"></div>
              <div class="dot"></div>
              <div class="dot"></div>
            </div>
          </button>

        </form>
      </div>
    </div>
  `
})
export class ChangePasswordComponent {

  @Input() state: PageState;
  @Output() submitted = new EventEmitter<ChangePasswordData>();

  form: FormGroup;

  constructor() {
    const passwordControl = new FormControl('',
      [Validators.required, Validators.pattern(environment.passwordPattern)]);

    this.form = new FormGroup({
      password: new FormControl('', [Validators.required, Validators.pattern(environment.passwordPattern)]),
      newPassword: passwordControl,
      passwordConfirm: new FormControl('', [Validators.required, CustomValidators.equalTo(passwordControl)])
    });

  }

  onSubmit(): void {
    if (this.form.valid) {
      const data: ChangePasswordData = {
        password: this.form.value.password,
        newPassword: this.form.value.newPassword
      };
      this.submitted.emit(data);
    }
  }
}
