import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NotifierModule } from 'angular-notifier';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AlwaysShowModule } from 'ng-always-show';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { PageNotFoundPageComponent } from './containers/page-not-found.page';
import { AppComponent } from './containers/app.component';
import { JsonInterceptorService } from './services/json-interceptor.service';
import { TokenInterceptorService } from './services/toke-interceptor.service';
import { PublicRootComponent } from './containers/public-root.component';
import { SecureRootComponent } from './containers/secure-root.component';
import { HeaderComponent } from './components/header.component';
import { FEATURE_NAME, reducers } from './reducers';
import { SideMenuComponent } from './components/side-menu.component';
import { AppEffects } from './effects/app.effects';

const COMPONENTS = [
  AppComponent,
  PublicRootComponent,
  SecureRootComponent,
  PageNotFoundPageComponent,
  HeaderComponent,
  SideMenuComponent
];

@NgModule({
  declarations: COMPONENTS,
  exports: COMPONENTS,
  imports: [
    CommonModule,
    RouterModule,
    NotifierModule,
    AlwaysShowModule,
    NgbModule,
    StoreModule.forFeature(FEATURE_NAME, reducers),
    EffectsModule.forFeature([
      AppEffects
    ])
  ]
})
export class CoreModule {

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: TokenInterceptorService,
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: JsonInterceptorService,
          multi: true
        }
      ]
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule)
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only');
  }
}
