import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { LoginPageComponent } from './containers/login-page.component';
import { LoginFormComponent } from './components/login-form.component';
import { AuthService } from './services/auth.service';
import { AuthGuard } from './guards/auth-guard.service';
import { AuthEffects } from './effects/auth.effects';
import { FEATURE_NAME, reducers } from './reducers';
import { ForgotPasswordComponent } from './components/forgot-password.component';
import { ForgotPasswordPageComponent } from './containers/forgot-password-page.component';
import { NotAuthorizedPageComponent } from './containers/not-autorized.page';
import { ActivateUserPageComponent } from './containers/activate-user-page.component';
import { ActivateUseFormComponent } from './components/activate-use-form.component';
import { DpcCommonModule } from '../common/common.module';
import { PublicRootComponent } from '../core/containers/public-root.component';
import { ChangePasswordComponent } from './components/change-password.component';
import { ChangePasswordPageComponent } from './containers/change-password-page.component';
import { SecureRootComponent } from '../core/containers/secure-root.component';
import { JwtHelperService } from '@auth0/angular-jwt';

export const COMPONENTS = [
  LoginPageComponent,
  LoginFormComponent,
  ForgotPasswordComponent,
  ForgotPasswordPageComponent,
  NotAuthorizedPageComponent,
  ActivateUseFormComponent,
  ActivateUserPageComponent,
  ChangePasswordComponent,
  ChangePasswordPageComponent
];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule.forChild([
      {
        path: 'auth', component: PublicRootComponent, children: [
          { path: 'login', component: LoginPageComponent },
          { path: 'forgot-password', component: ForgotPasswordPageComponent },
          { path: 'activation/:activationToken', component: ActivateUserPageComponent }
        ]
      },
      {
        path: 'user', component: SecureRootComponent, children: [
          { path: 'change-password', component: ChangePasswordPageComponent }
        ]
      }
    ]),
    StoreModule.forFeature(FEATURE_NAME, reducers),
    EffectsModule.forFeature([AuthEffects]),
    DpcCommonModule
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS
})
export class AuthModule {
  static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [AuthService, AuthGuard, JwtHelperService]
    };
  }
}
