import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import * as fromAuth from '../reducers';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private store: Store<fromAuth.State>,
              private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.store.pipe(
      select(fromAuth.selectAuthStatusState),
      take(1),
      map(state => {
        if (!state.loggedIn) {
          this.router.navigate(['/auth/login']);

          return false;
        }

        if (!route.data || !route.data.roles)
          return true;

        if (!Array.isArray(route.data.roles))
          throw new Error('Router role invalid type');

        const roles = route.data.roles as Array<string>;

        const user = state.user;

        const hasRight = roles.some(requiredRole => user.role === requiredRole);

        if (hasRight)
          return true;

        this.router.navigate(['/not-authorized']);

        return false;
      })
    );
  }
}
