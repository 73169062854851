import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { IMultiSelectOption } from 'angular-2-dropdown-multiselect';

import * as fromOrg from '../reducers';

// tslint:disable-next-line: pipe-impure
@Pipe({ name: 'dpcOrgName', pure: false })
export class OrgNamePipe implements PipeTransform, OnDestroy {
  private organisations$: Observable<Array<IMultiSelectOption>>;
  private productSubscription: Subscription;

  private orgs: Array<IMultiSelectOption> = [];

  constructor(private _store: Store<fromOrg.State>) {
    this.organisations$ = this._store.select(fromOrg.getOrgMultiSelectList);
    this.productSubscription = this.organisations$.subscribe(data => {
      this.orgs = data;
    });
  }

  transform(value: string): string {
    for (const org of this.orgs)
      if (org.id === Number.parseInt(value))
        return org.name;

    return '';
  }

  ngOnDestroy(): void {
    if (this.productSubscription)
      this.productSubscription.unsubscribe();
  }
}
