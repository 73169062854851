import { NumberFormatOptions } from '../model';

const DEFAULT_FORMAT: NumberFormatOptions = {
  decimalSeparator: ',',
  thousandSeparator: '.',
  decimalPlaces: 2
};

export const formatNumber = (num: number | string, options?: NumberFormatOptions): string => {
  if (num === null || num === undefined)
    return null;

  if (typeof num === 'string')
    num =  Number(num);

  const opt = {
    ...DEFAULT_FORMAT,
    ...options
  };

  const rounded = precisionRound(num, opt.decimalPlaces);

  const split = rounded.toString().split('.');
  const intArr = split[0].split('');
  for (let i = intArr.length - 3; i > 0; i -= 3)
    intArr.splice(i, 0, opt.thousandSeparator);

  let decimalPart = '';

  if (opt.decimalPlaces > 0) {
    let dec = Array(opt.decimalPlaces).fill('0');

    if (split.length === 2)
      dec = [...split[1].split(''), ...dec]
        .slice(0, opt.decimalPlaces);

    decimalPart = `${opt.decimalSeparator}${dec.join('')}`;
  }

  if (intArr[0] === '-' && intArr[1] === opt.thousandSeparator)
    intArr.splice(1, 1);

  return intArr.join('') + decimalPart;
};

export const precisionRound = (num, precision): number => {
  const factor = Math.pow(10, precision);

  return Math.round(num * factor) / factor;
};
