import { Action } from '@ngrx/store';

import { User } from '../../auth/models/user';

export enum UserActionTypes {
  LoadUsers = '[User] Load users',
  LoadUsersSuccess = '[User] Load users success',
  LoadUsersFailed = '[User] Load users failed',
  AddUser = '[User] Add user',
  EditUser = '[User] Edit user',
  CancelEditUser = '[User] Cancel edit user',
  SaveUser = '[User] Save user',
  SaveUserSuccess = '[User] Save user success',
  SaveUserFailed = '[User] Save user failed',
  DeleteUser = '[User] Delete user',
  DeleteUserSuccess = '[User] Delete user success',
  DeleteUserFailed = '[User] Delete user failed',
  SendActivationEmail = '[User] SendActivation email',
  SendActivationEmailSuccess = '[User] SendActivation email sucess',
  SendActivationEmailFailed = '[User] SendActivation email failed'
}

export class LoadUsersAction implements Action {
  type = UserActionTypes.LoadUsers;
}

export class LoadUsersSuccessAction implements Action {
  type = UserActionTypes.LoadUsersSuccess;

  constructor(public payload: Array<User>) {
  }
}

export class LoadUsersFailedAction implements Action {
  type = UserActionTypes.LoadUsersFailed;

  constructor(public payload: any) {
  }
}

export class AddUserAction implements Action {
  type = UserActionTypes.AddUser;
}

export class EditUserAction implements Action {
  type = UserActionTypes.EditUser;

  constructor(public payload: User) {
  }
}

export class CancelEditUserAction implements Action {
  type = UserActionTypes.CancelEditUser;
}

export class SaveUserAction implements Action {
  type = UserActionTypes.SaveUser;

  constructor(public payload: User) {
  }
}

export class SaveUserSuccessAction implements Action {
  type = UserActionTypes.SaveUserSuccess;

  constructor(public payload: User) {
  }
}

export class SaveUserFailedAction implements Action {
  type = UserActionTypes.SaveUserFailed;

  constructor(public payload: any) {
  }
}

export class DeleteUserAction implements Action {
  type = UserActionTypes.DeleteUser;

  constructor(public payload: User) {
  }
}

export class DeleteUserSuccessAction implements Action {
  type = UserActionTypes.DeleteUserSuccess;

  constructor(public payload: User) {
  }
}

export class DeleteUserFailedAction implements Action {
  type = UserActionTypes.DeleteUserFailed;

  constructor(public payload: any) {
  }
}

export class SendActivationEmailAction implements Action {
    type = UserActionTypes.SendActivationEmail;

  constructor(public payload: User) {
  }
}

export class SendActivationEmailSuccessAction implements Action {
  type = UserActionTypes.SendActivationEmailSuccess;

  constructor(public payload: User) {
  }
}

export class SendActivationEmailFailedAction implements Action {
  type = UserActionTypes.SendActivationEmailFailed;

  constructor(public payload: User) {
  }
}

export type UserActions =
  LoadUsersAction
  | LoadUsersSuccessAction
  | LoadUsersFailedAction
  | AddUserAction
  | EditUserAction
  | CancelEditUserAction
  | SaveUserAction
  | SaveUserSuccessAction
  | SaveUserFailedAction
  | DeleteUserAction
  | DeleteUserSuccessAction
  | DeleteUserFailedAction
  | SendActivationEmailAction
  | SendActivationEmailSuccessAction
  | SendActivationEmailFailedAction
  ;
