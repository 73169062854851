import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ExistingProvider, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as moment from 'moment';
import { noop } from 'rxjs';
import { DATE_FORMAT } from '../util/constants';

const SELECT_DATE_ACCESSOR: ExistingProvider = {
  provide: NG_VALUE_ACCESSOR,
  // tslint:disable-next-line: no-forward-ref
  useExisting: forwardRef(() => DpcDateComponent),
  multi: true
};

@Component({
  selector: 'dpc-date-input',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SELECT_DATE_ACCESSOR],
  template: `
      <input class="form-control"
             [dpDayPicker]="datePickerConfig"
             [(ngModel)]="selectedDate"
             placeholder="dd/mm/yyyy"
             theme="dp-material">
  `
})
export class DpcDateComponent implements ControlValueAccessor {
  @Input() label = 'Date:';
  @Input() disabled = false;

  _selectedDate: Date;
  datePickerConfig = {
    format: DATE_FORMAT,
    closeOnSelect: true
  };

  get selectedDate(): string {
    if (!this._selectedDate)
      return '';

    return moment(this._selectedDate).format(DATE_FORMAT);
  }

  set selectedDate(value: string) {
    const m = moment(value, DATE_FORMAT, true);

    if (!m.isValid())
      return;

    const date = m.toDate();
    if (date.getTime() === this._selectedDate.getTime())
      return;

    this._selectedDate = date;
    this.onChangeCallback(date);
  }

  private onChangeCallback: (_: any) => void = noop;
  private onTouchedCallback: () => void = noop;

  constructor(private cdRef: ChangeDetectorRef) {

  }

  onBlur(): void {
    this.onTouchedCallback();
  }

  // Start ControlValueAccessor
  writeValue(obj: Date): void {
    if (this._selectedDate !== obj) {
      this._selectedDate = obj;
      this.cdRef.markForCheck();
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  // End ControlValueAccessor
}
