import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Organization } from '../models/organization';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import cloneDeep from 'lodash-es/cloneDeep';
import { IMultiSelectOption } from 'angular-2-dropdown-multiselect';

@Component({
  selector: 'dpc-org-list',
  template: `
    <div class="container-fluid">
      <div class="row mt-3">
        <div class="col-md-12">
          <table class="daikin-table">
            <thead>
              <tr>
                <th>
                  Organization
                </th>
                <th>
                  Parent organization
                </th>
                <th>
                  Currency
                </th>
                <th class="actions-cell"><!-- Actions --></th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let org of orgList">

                <ng-container *ngIf="org.id !== editingOrg?.id">
                  <ng-container *ngTemplateOutlet="readonlyTemplate; context: {org: org}"></ng-container>
                </ng-container>

                <ng-container *ngIf="org.id === editingOrg?.id">
                  <ng-container *ngTemplateOutlet="editTemplate; context: {org: org}"></ng-container>
                </ng-container>

              </ng-container>

              <ng-container *ngIf="editingOrg && !editingOrg.id">
                <ng-container *ngTemplateOutlet="editTemplate; context: {org: org}"></ng-container>
              </ng-container>

            </tbody>
          </table>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-md-12">
          <button class="btn btn-primary"
                  (click)="addNewOrganization.emit()"
                  *ngIf="hasRightToAddEditOrg">Add new Organization
          </button>
        </div>
      </div>
    </div>

    <ng-template #readonlyTemplate let-org="org">
      <tr>
        <td>{{ org.name }}</td>
        <td class="property-value">{{ org.parentOrgId | dpcOrgName }}</td>
        <td class="property-value">{{ org.currency }}</td>
        <td class="actions-cell">
          <button *ngIf="hasRightToAddEditOrg"
                  type="button"
                  class="btn btn-link btn-icon"
                  (click)="editOrganization.emit(org)">
            <span class="fa fa-edit text-primary"></span>
          </button>
        </td>
      </tr>
    </ng-template>

    <ng-template #editTemplate let-org="org">
      <tr [formGroup]="form">
        <td>
          <input type="text"
                 class="form-control"
                 id="orgName"
                 placeholder="Organization name"
                 formControlName="name">
        </td>
        <td class="property-value">
          <dpc-select id="parentOrg"
                      formControlName="parentOrgId"
                      [options]="parentOrgList"></dpc-select>
        </td>
        <td class="property-value">
          <dpc-select id="currency"
                      formControlName="currency"
                      otherEnabled
                      [options]="currencies"
                      otherMaxLength="3"
                      otherTitle="Add new currency"></dpc-select>
        </td>
        <td class="actions-cell">
          <button type="button"
                  class="btn btn-link btn-icon"
                  (click)="cancelEditOrganization.emit()">
            <span class="fa fa-times-circle text-danger"></span>
          </button>
          <button type="button"
                  class="btn btn-link btn-icon"
                  [disabled]="form.invalid"
                  (click)="onSubmit()">
            <span class="fa fa-check-circle text-secondary"></span>
          </button>
        </td>
      </tr>
    </ng-template>
  `
})

export class OrgListComponent implements OnChanges {
  @Input() orgList: Array<Organization>;
  @Input() editingOrg: Organization;
  @Input() hasRightToAddEditOrg: boolean;
  @Input() parentOrgList: Array<Organization>;
  @Input() currencies: Array<IMultiSelectOption> = [];

  @Output() addNewOrganization = new EventEmitter();
  @Output() editOrganization = new EventEmitter<Organization>();
  @Output() saveOrganization = new EventEmitter<Organization>();
  @Output() cancelEditOrganization = new EventEmitter<Organization>();

  form = new FormGroup({
    name: new FormControl('', [Validators.required]),
    parentOrgId: new FormControl(),
    currency: new FormControl('', [Validators.required])
  });

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['editingOrg'] && changes['editingOrg'].currentValue) {
      const value = cloneDeep(changes['editingOrg'].currentValue);

      value.parentOrgId = value.parentOrgId ? [value.parentOrgId] : [];
      value.currency = value.currency ? [value.currency] : [];

      this.form.patchValue(value);
    }
  }

  onSubmit(): void {
    if (!this.form.valid)
      return;

    const value: Organization = {
      ...this.editingOrg,
      ...this.form.value
    };

    if (value.parentOrgId)
      value.parentOrgId = value.parentOrgId[0];

    if (value.currency)
      value.currency = value.currency[0];

    this.saveOrganization.emit(value);
  }

}
