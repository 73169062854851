import { Action } from '@ngrx/store';

import { Organization } from '../models/organization';

export const LIST_ORG = '[Org] list organizations';
export const LIST_ORG_SUCCESS = '[Org] list organizations success';
export const LIST_ORG_FAILED = '[Org] list organizations failed';
export const ADD_ORG = '[Org] add new organization';
export const EDIT_ORG = '[Org] add edit organization';
export const CANCEL_EDIT_ORG = '[Org] add cancel edit organization';
export const SAVE_ORG = '[Org] save organization';
export const SAVE_ORG_SUCCESS = '[Org] save organization success';
export const SAVE_ORG_FAILED = '[Org] save organization failed';
export const LOAD_ORG = '[Org] load organization';
export const LOAD_ORG_SUCCESS = '[Org] load organization success';
export const LOAD_ORG_FAILED = '[Org] load organization failed';

export class ListOrgAction implements Action {
  type = LIST_ORG;
}

export class ListOrgSuccessAction implements Action {
  type = LIST_ORG_SUCCESS;

  constructor(public payload: Array<Organization>) {
  }
}

export class ListOrgFailedAction implements Action {
  type = LIST_ORG_FAILED;

  constructor(public payload: any) {
  }
}

export class AddOrgAction implements Action {
  type = ADD_ORG;
}

export class EditOrgAction implements Action {
  type = EDIT_ORG;

  constructor(public payload: Organization) {
  }
}

export class CancelEditOrgAction implements Action {
  type = CANCEL_EDIT_ORG;
}

export class SaveOrgAction implements Action {
  type = SAVE_ORG;

  constructor(public payload: Organization) {
  }
}

export class SaveOrgSuccessAction implements Action {
  type = SAVE_ORG_SUCCESS;

  constructor(public payload: Organization) {
  }
}

export class SaveOrgFailedAction implements Action {
  type = SAVE_ORG_FAILED;

  constructor(public payload: any) {
  }
}

export class LoadOrgAction implements Action {
  type = LOAD_ORG;

  constructor(public payload: number) {
  }
}

export class LoadOrgSuccessAction implements Action {
  type = LOAD_ORG_SUCCESS;

  constructor(public payload: Organization) {
  }
}

export class LoadOrgFailedAction implements Action {
  type = LOAD_ORG_FAILED;

  constructor(public payload: any) {
  }
}

export type OrgActions =
  ListOrgAction
  | ListOrgSuccessAction
  | ListOrgFailedAction
  | AddOrgAction
  | EditOrgAction
  | CancelEditOrgAction
  | SaveOrgAction
  | SaveOrgSuccessAction
  | SaveOrgFailedAction
  | LoadOrgAction
  | LoadOrgSuccessAction
  | LoadOrgFailedAction
  ;
