import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as fromAuth from '../reducers';
import * as Auth from '../actions/auth.actions';
import { Authenticate } from '../models/authenticate';

@Component({
  selector: 'dpc-login-page',
  template: `
  <div class="row justify-content-md-center">
    <div class="col-md-6">
      <h1 class="text-center">Login</h1>
    </div>
  </div>

  <dpc-login-form
  (submitted)="onSubmit($event)"
  [processing]="processing$ | async"
  [error]="error$ | async">
  </dpc-login-form>
  `
})
export class LoginPageComponent {

  error$: Observable<string>;
  processing$: Observable<boolean>;

  constructor(private _store: Store<fromAuth.State>) {
    this.error$ = this._store.select(fromAuth.getLoginPageError);
    this.processing$ = this._store.select(fromAuth.getLoginPagePending);
  }

  onSubmit($event: Authenticate): void {
    this._store.dispatch(new Auth.Login($event));
  }

}
