import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromRoot from '../../reducers';
import * as fromApp from './app.reducer';

export const FEATURE_NAME = 'appData';

export interface AppFeatureState {
  app: fromApp.State;
}
export interface State extends fromRoot.State {
  appState: AppFeatureState;
}

export const reducers = {
  app: fromApp.reducer
};

export const getAppFeatureState = createFeatureSelector<AppFeatureState>(FEATURE_NAME);

export const getAppState = createSelector(getAppFeatureState, state => state.app);

export const getHeaderLogoSrc = createSelector(getAppState, fromApp.getHeaderLogoSrc);
export const isIE11 = createSelector(getAppState, fromApp.isIE11);
