import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { ReactiveFormsModule } from '@angular/forms';
import { EffectsModule } from '@ngrx/effects';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ChartModule } from 'primeng/chart';

import { DpcCommonModule } from '../common/common.module';
import { ReportService } from './services/report.service';
import { FEATURE_NAME, reducers } from './reducers';
import { ReportEffect } from './effects/report.effect';
import { ProductStatisticComponent } from './components/product-statistic.component';
import { PriceCollectorAuditLogComponent } from './components/price-collector-audit-log.component';
import { PriceStatisticComponent } from './components/price-statistic.component';

const COMPONENTS = [
  ProductStatisticComponent,
  PriceCollectorAuditLogComponent,
  PriceStatisticComponent
];

@NgModule({
  declarations: COMPONENTS,
  exports: COMPONENTS,
  imports: [
    CommonModule,
    RouterModule,
    ChartModule,
    ReactiveFormsModule,
    NgbModule,
    DpcCommonModule,
    StoreModule.forFeature(FEATURE_NAME, reducers),
    EffectsModule.forFeature([
      ReportEffect
    ])
  ],
  providers: [
    ReportService
  ]
})
export class ReportModule {
  static forRoot(): ModuleWithProviders<ReportModule> {
    return {
      ngModule: ReportModule
    };
  }
}
