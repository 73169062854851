import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { State } from '../reducers/app.reducer';

@Component({
  selector: 'dpc-side-menu',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div>
      <div always-show topOffset="0">
        <nav class="navbar">
          <ul class="list-unstyled w-100">
            <li class="nav-item"
                routerLinkActive="active"
                ngbTooltip="Dashboard"
                placement="right"
                [disableTooltip]="state.sideMenuVisible">
              <a class="nav-link"
                 [routerLink]="['/dashboard']">
                <span class="fa fa-tachometer"></span>
                <span *ngIf="state.sideMenuVisible">Dashboard</span>
              </a>
            </li>
            <li class="nav-item"
                routerLinkActive="active"
                ngbTooltip="Compare products"
                placement="right"
                [disableTooltip]="state.sideMenuVisible">
              <a class="nav-link"
                 [routerLink]="['/product','list']"
                 [queryParams]="{ productType:1 }">
                <span class="fa fa-exchange"></span>
                <span *ngIf="state.sideMenuVisible">Compare products</span>
              </a>
            </li>
            <li *ngIf="userIsAdmin"
                class="nav-item"
                [class.group-menu]="state.adminMenuExpanded"
                ngbTooltip="Administration"
                placement="right"
                [disableTooltip]="state.sideMenuVisible">
              <a class="nav-link" (click)="toggleAdminMenu.emit()">
                <span class="fa fa-home"></span>
                <span *ngIf="state.sideMenuVisible">Administration</span>
                <span *ngIf="state.sideMenuVisible" class="dropdown-toggle float-right"></span>
              </a>
            </li>
            <li *ngIf="userIsAdmin">
              <div class="collapse group-menu"
                   [class.show]="state.adminMenuExpanded">
                <ul class="list-unstyled w-100">
                  <li class="nav-item"
                      routerLinkActive="active"
                      ngbTooltip="Meta Db"
                      placement="right"
                      [disableTooltip]="state.sideMenuVisible">
                    <a class="nav-link" [routerLink]="['/product/metadata']">
                      <span class="fa fa-database"></span>
                      <span *ngIf="state.sideMenuVisible">Meta Db</span>
                    </a>
                  </li>

                  <li class="nav-item"
                      routerLinkActive="active"
                      ngbTooltip="Organizations"
                      placement="right"
                      [disableTooltip]="state.sideMenuVisible">
                    <a class="nav-link" [routerLink]="['/org','list']">
                      <span class="fa fa-building"></span>
                      <span *ngIf="state.sideMenuVisible">Organizations</span>
                    </a>
                  </li>

                  <li class="nav-item"
                      routerLinkActive="active"
                      ngbTooltip="Users"
                      placement="right"
                      [disableTooltip]="state.sideMenuVisible">
                    <a class="nav-link" [routerLink]="['/user','list']">
                      <span class="fa fa-user"></span>
                      <span *ngIf="state.sideMenuVisible">Users</span>
                    </a>
                  </li>

                  <li class="nav-item"
                      routerLinkActive="active"
                      ngbTooltip="Exchanges"
                      placement="right"
                      [disableTooltip]="state.sideMenuVisible">
                    <a class="nav-link" [routerLink]="['/exchange', 'list']">
                      <span class="fa fa-line-chart"></span>
                      <span *ngIf="state.sideMenuVisible">Exchanges</span>
                    </a>
                  </li>

                  <li class="nav-item"
                      routerLinkActive="active"
                      ngbTooltip="Export/Import"
                      placement="right"
                      [disableTooltip]="state.sideMenuVisible">
                    <a class="nav-link" [routerLink]="['/product/export-import']">
                      <span class="fa fa-arrows-v"></span>
                      <span *ngIf="state.sideMenuVisible">Export/Import</span>
                    </a>
                  </li>

                </ul>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  `,
  styleUrls: ['./side-menu.component.scss']

})

export class SideMenuComponent {
  @Input() state: State;
  @Input() userIsAdmin: boolean;

  @Output() toggleAdminMenu = new EventEmitter();
}
