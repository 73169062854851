import { Routes } from '@angular/router';

import { PageNotFoundPageComponent } from './core/containers/page-not-found.page';
import { SecureRootComponent } from './core/containers/secure-root.component';
import { PublicRootComponent } from './core/containers/public-root.component';
import { AuthGuard } from './auth/guards/auth-guard.service';
import { OrgListPageComponent } from './org/containers/org-list-page.component';
import { UserListPageComponent } from './org/containers/user-list-page.component';
import { NotAuthorizedPageComponent } from './auth/containers/not-autorized.page';

export const appRoutes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },

  {
    path: 'dashboard',
    component: SecureRootComponent,
    loadChildren: './dashboard/dashboard.module#DashboardModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'product',
    component: SecureRootComponent,
    loadChildren: './product/product.module#ProductModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'exchange',
    component: SecureRootComponent,
    loadChildren: './exchange/exchange.module#ExchangeModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'org',
    component: SecureRootComponent,
    children: [
      { path: '', redirectTo: 'list', pathMatch: 'full' },
      { path: 'list', component: OrgListPageComponent }
    ],
    canActivate: [AuthGuard]
  },
  {
    path: 'user',
    component: SecureRootComponent,
    children: [
      { path: '', redirectTo: 'list', pathMatch: 'full' },
      { path: 'list', component: UserListPageComponent }
    ],
    canActivate: [AuthGuard]
  },
  {
    path: 'not-found',
    component: PublicRootComponent,
    children: [
      { path: '', component: PageNotFoundPageComponent }
    ]
  },
  {
    path: 'not-authorized',
    component: PublicRootComponent,
    children: [
      { path: '', component: NotAuthorizedPageComponent }
    ]
  },
  { path: '**', redirectTo: 'not-found' }
];
