import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { State } from '../reducers/price-collector-audit-log.reducer';
import { dateToDpObject, dpObjectToDate } from '../../shared/date-mapper';
import { PriceCollectorAuditLogForm } from '../models/price-collector-audit-log-form';

@Component({
  selector: 'dpc-price-collector-audit-log',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <h3>Price collector log</h3>
    <form [formGroup]="form" (ngSubmit)="onSubmit()" class="form-inline">
      <label for="startDate" class="mr-2">Date from</label>
      <div class="input-group mr-2">
        <input id="startDate"
               class="form-control"
               placeholder="yyyy-mm-dd"
               formControlName="startDate"
               ngbDatepicker
               #startDate="ngbDatepicker"
               (focus)="startDate.open()">
        <div class="input-group-append">
          <button class="btn btn-outline-primary" (click)="startDate.toggle()" type="button">
            <span class="daikin-calendar-icon"></span>
          </button>
        </div>
      </div>

      <label for="endDate" class="mr-2">Date to</label>
      <div class="input-group mr-2">
        <input id="endDate"
               class="form-control"
               placeholder="yyyy-mm-dd"
               formControlName="endDate"
               ngbDatepicker
               #endDate="ngbDatepicker"
               (focus)="endDate.open()">
        <div class="input-group-append">
          <button class="btn btn-outline-primary" (click)="endDate.toggle()" type="button">
            <span class="daikin-calendar-icon"></span>
          </button>
        </div>
      </div>

      <button type="submit" class="btn btn-primary">Filter</button>
    </form>

    <div class="mt-4">
      <div class="d-inline-block pull-right">
        <ngb-pagination [collectionSize]="state.count"
                        [page]="state.currentPage"
                        (pageChange)="pageChange.emit($event)"
                        maxSize="10"
                        [pageSize]="state.recordPerPage"
                        [rotate]="true"
                        size="sm"
                        aria-label="Top pagination">
        </ngb-pagination>
      </div>
    </div>

    <div>
      <table class="daikin-table">
        <thead>
          <tr>
            <th>
              Product
            </th>
            <th>
              Date
            </th>
            <th>
              Domain
            </th>
            <th>
              Status
            </th>
            <th>
              Message
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let row of state.rows">
            <td>
              <a [routerLink]="['/product', 'view', row.combinationId]"
                 [queryParams]="{ productType: row.productTypeId }">{{ row.brand | dpcDash }} {{ row.materialName }}</a>
            </td>
            <td>
              {{ row.date | date:'yyyy-MM-dd' }}
            </td>
            <td>
              <a [href]="row.domain">{{ row.domain }}</a>
            </td>
            <td>
              {{ row.status }}
            </td>
            <td>
              {{ row.message }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="mt-2">
      <div class="d-inline-block pull-right">
        <ngb-pagination [collectionSize]="state.count"
                        [page]="state.currentPage"
                        (pageChange)="pageChange.emit($event)"
                        maxSize="10"
                        [rotate]="true"
                        [pageSize]="state.recordPerPage"
                        size="sm"
                        aria-label="Down pagination">
        </ngb-pagination>
      </div>
    </div>

    <div class="clearfix"></div>
  `
})
export class PriceCollectorAuditLogComponent implements OnChanges {
  @Input() state: State;

  @Output() filterChange = new EventEmitter<PriceCollectorAuditLogForm>();
  @Output() pageChange = new EventEmitter<number>();

  form = new FormGroup({
    startDate: new FormControl('', [Validators.required]),
    endDate: new FormControl('', [Validators.required])
  });

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.state)
      this.form.patchValue({
        startDate: dateToDpObject(this.state.startDate),
        endDate: dateToDpObject(this.state.endDate)
      });
  }

  onSubmit(): void {
    if (!this.form.valid)
      return;

    const value: PriceCollectorAuditLogForm = {
      startDate: dpObjectToDate(this.form.value.startDate),
      endDate: dpObjectToDate(this.form.value.endDate)
    };

    this.filterChange.emit(value);
  }
}
