import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, EventEmitter,
  ExistingProvider,
  forwardRef,
  Input,
  OnChanges, Output,
  SimpleChanges, ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IMultiSelectOption, IMultiSelectSettings, MultiselectDropdownComponent } from 'angular-2-dropdown-multiselect';
import { noop } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import cloneDeep from 'lodash-es/cloneDeep';

import { AddOtherValueComponent } from './add-other-value.component';

const SELECT_ACCESSOR: ExistingProvider = {
  provide: NG_VALUE_ACCESSOR,
  // tslint:disable-next-line: no-forward-ref
  useExisting: forwardRef(() => DpcSelectComponent),
  multi: true
};

@Component({
  selector: 'dpc-select',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SELECT_ACCESSOR],
  template: `
    <ss-multiselect-dropdown #ssDropdown
                             [options]="_options"
                             [ngModel]="model"
                             [disabled]="disabled"
                             (ngModelChange)="onChange($event)"
                             (dropdownClosed)="dropdownClosed.emit()"
                             [settings]="settings"></ss-multiselect-dropdown>
  `
})
export class DpcSelectComponent implements ControlValueAccessor, OnChanges {
  @Input() options: Array<IMultiSelectOption>;
  @Input() multi = false;
  @Input() otherTitle = 'Add new item';
  @Input() otherMaxLength: number;
  @Input() filterControl = false;

  @Input()
  set otherEnabled(value: boolean | string) {
    this._otherEnabled = value !== 'false';
  }

  @Output() dropdownClosed = new EventEmitter();
  @ViewChild('ssDropdown') ssDropdown: MultiselectDropdownComponent;
  settings: IMultiSelectSettings = {
    enableSearch: true,
    buttonClasses: 'form-control btn text-left',
    containerClasses: '',
    pullRight: true,
    ...SINGLE_SELECT_SETTINGS
  };

  model: any;
  disabled = false;
  _options: Array<IMultiSelectOption> = [];
  _otherEnabled = false;

  private onChangeCallback: (_: any) => void = noop;
  private onTouchedCallback: () => void = noop;
  private prevModel: any;

  constructor(private cdr: ChangeDetectorRef,
              private modalService: NgbModal) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['multi'])
      this.setMultiSelectSettings(changes['multi'].currentValue);

    if (changes['options'] || changes['otherEnabled'])
      this.setInternalOptions();
  }

  writeValue(obj: any): void {
    if (this.model === obj)
      return;

    this.prevModel = cloneDeep(obj);
    this.model = obj;
    this.setMultiSelectSettings(this.multi);
    this.cdr.markForCheck();
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onChange($event): void {
    if (containsOther($event)) {
      const originalFocusBack = this.ssDropdown.settings.focusBack;
      this.ssDropdown.settings.focusBack = false;
      const modalRef = this.modalService.open(AddOtherValueComponent);
      modalRef.componentInstance.title = this.otherTitle;
      modalRef.componentInstance.maxLength = this.otherMaxLength;

      modalRef.result
        .then(result => {
          if (result.command !== 'add')
            return;

          const newOptions = {
            id: result.value,
            name: result.value
          };

          this.options = cloneDeep(this.options || []);

          this.options.push(newOptions);
          this.setInternalOptions();
          const newModel = this.multi ? cloneDeep(this.prevModel) : [];
          newModel.push(newOptions.id);

          this.writeValue(newModel);
          this.ssDropdown.settings.focusBack = originalFocusBack;
          this.onChangeCallback(newModel);
        })
        .catch(err => {
          this.model = cloneDeep(this.prevModel);
          this.ssDropdown.settings.focusBack = originalFocusBack;
        });
    } else
      this.onChangeCallback($event);
  }

  private setMultiSelectSettings(multi: boolean): void {
    this.settings = multi ? { ...this.settings, ...MULTI_SELECT_SETTINGS } : { ...this.settings, ...SINGLE_SELECT_SETTINGS };

    if (this.filterControl && this.model && this.model.length > 0)
      this.settings.buttonClasses += ' filter-changed';
  }

  private setInternalOptions(): void {
    this._options = [...this.options || []];

    if (this._otherEnabled)
      this._options.push(OTHER_VALUE);
  }
}

const SINGLE_SELECT_SETTINGS = {
  selectionLimit: 1,
  autoUnselect: true,
  closeOnSelect: true
};

const MULTI_SELECT_SETTINGS = {
  selectionLimit: 0,
  autoUnselect: false,
  closeOnSelect: false
};

const OTHER_VALUE = {
  id: '___other___',
  name: 'Other'
};

function containsOther(values: Array<string>): boolean {
  return values.some(x => x === OTHER_VALUE.id);
}
