import { Component } from '@angular/core';

@Component({
  selector: 'dpc-public-root',
  template: `
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <header>
          </header>
        </div>
      </div>

      <router-outlet></router-outlet>
    </div>
  `
})
export class PublicRootComponent {
}
