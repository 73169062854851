import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { NotifierService } from 'angular-notifier';
import { map, tap } from 'rxjs/operators';

import { APP_SHOW_NOTIFICATION, ShowNotificationAction } from '../actions/app.actions';
import { Notification, NotificationType } from '../models';

@Injectable()
export class ApplicationEffects {

  @Effect({ dispatch: false })
  showNotification$: Observable<Action> = this.actions$.pipe(
    ofType<ShowNotificationAction>(APP_SHOW_NOTIFICATION),
    map(action => action.payload),
    tap((notification: Notification) => {
      if (notification.type === NotificationType.Undoable)
        return;

      this.notifierService.notify(notification.type, notification.message);
    })
  );

  constructor(private actions$: Actions,
              private notifierService: NotifierService) {
  }
}
