import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'dpc-page-not-found',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="row">
       <div class="col-md-12">
            <h1 class="text-center">
                Page not found
            </h1>
        </div>
    </div>
`
})
export class PageNotFoundPageComponent {
}
