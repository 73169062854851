import { Action } from '@ngrx/store';

export const FORGOT_PASSWORD = '[Auth] Forgot password';
export const FORGOT_PASSWORD_SUCCESS = '[Auth] Forgot password success';
export const FORGOT_PASSWORD_FAILED = '[Auth] Forgot password failed';

export class ForgotPasswordAction implements Action {
  readonly type = FORGOT_PASSWORD;

  constructor(public payload: string) {
  }
}

export class ForgotPasswordSuccessAction implements Action {
  readonly type = FORGOT_PASSWORD_SUCCESS;
}

export class ForgotPasswordFailedAction implements Action {
  readonly type = FORGOT_PASSWORD_FAILED;

  constructor(public payload: any) {
  }
}

export type Actions =
  ForgotPasswordAction
  | ForgotPasswordSuccessAction
  | ForgotPasswordFailedAction
  ;
