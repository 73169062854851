import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  ExistingProvider,
  forwardRef,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IMultiSelectOption, IMultiSelectSettings } from 'angular-2-dropdown-multiselect';
import { noop } from 'rxjs';

const YES_NO_ACCESSOR: ExistingProvider = {
  provide: NG_VALUE_ACCESSOR,
  // tslint:disable-next-line: no-forward-ref
  useExisting: forwardRef(() => DpcYesNoComponent),
  multi: true
};

@Component({
  selector: 'dpc-yes-no',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [YES_NO_ACCESSOR],
  template: `
    <ss-multiselect-dropdown [options]="options"
                             [ngModel]="model"
                             [disabled]="disabled"
                             (ngModelChange)="onChange($event)"
                             (dropdownClosed)="dropdownClosed.emit()"
                             [settings]="settings"></ss-multiselect-dropdown>
  `
})
export class DpcYesNoComponent implements ControlValueAccessor, OnChanges {
  @Input() filterControl = false;
  @Output() dropdownClosed = new EventEmitter();

  options: Array<IMultiSelectOption> = [
    {
      id: 1,
      name: 'Yes'
    },
    {
      id: 0,
      name: 'No'
    }
  ];

  settings: IMultiSelectSettings = {
    enableSearch: true,
    buttonClasses: 'form-control btn btn-light text-left',
    containerClasses: '',
    ...SINGLE_SELECT_SETTINGS
  };

  model: any;
  disabled = false;

  private onChangeCallback: (_: any) => void = noop;
  private onTouchedCallback: () => void = noop;

  constructor(private cdr: ChangeDetectorRef) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filterControl)
      this.calculateSettings();
  }

  // Value Control Accessor
  writeValue(obj: any): void {
    if (this.model === obj)
      return;

    if (obj === null || obj === undefined)
      return;

    this.model = obj === true ? 1 : 0;
    this.calculateSettings();
    this.cdr.markForCheck();
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onChange($event): void {
    if ($event.length === 0)
      this.onChangeCallback(null);
    else if ($event[0] === 1)
      this.onChangeCallback(true);
    else
      this.onChangeCallback(false);
  }

  private calculateSettings(): void {
    this.settings = {
      ...this.settings,
      buttonClasses: 'form-control btn btn-light text-left'
    };

    if (this.filterControl && this.model > -1)
      this.settings.buttonClasses += ' filter-changed';
  }
}

const SINGLE_SELECT_SETTINGS = {
  selectionLimit: 1,
  autoUnselect: true
};
