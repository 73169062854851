import { Pipe, PipeTransform } from '@angular/core';
import { formatNumber } from '../util';

@Pipe({
  name: 'dpcNumberFormat'
})
export class NumberFormatPipe implements PipeTransform {
  transform(value: any, scale: number): any {
    return formatNumber(value, { decimalPlaces: scale });
  }
}
